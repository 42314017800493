<template>
  <div>
    <v-container>
      <v-layout
        row
        align-center
      >
        <v-flex
          xs12
        >
          <div class="display-1">
            Our Related Services
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container
      grid-list-md
      :style="{ height: mainMessageHeight + 'px' }"
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          v-for='service in serviceList' :key='service.serviceName'
          xs6
        >
          <v-card color="#00000000" flat style="cursor: pointer" @click="goto(service.link)">
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">{{ service.serviceName }}</h3>
                <p> {{ service.description }} </p>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>

      </v-layout>
    </v-container>

  </div>
</template>

<script>
import Init from './Init.vue';

export default {
  components: {},
  name: 'RelatedService',
  mixins: [
    Init,
  ],
  data: () => ({
    mainMessageHeight: 0,
    serviceList: [],
  }),
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    init() {
      this.serviceList = [
        {
          serviceName: 'Note',
          link: 'https://note.mu/nomuranomura',
          description: 'Factor business models for small and medium-sized brands and forecast future performance. On that basis, it is a note that calculates the corporate value and predicts the appropriate market capitalization and stock price. We plan to publish 2-4 reports a month. Since past company analysis reports are published free of charge, if you want to see them once and read the latest reports, I\'d be happy if you could subscribe.',
        }, {
          serviceName: 'PERAGARU PASSIVE',
          link: 'https://passive.peragaru.com',
          description: 'This is a calculating service that the buying pressure of TOPIX for stocks that have been changed to TSE. Please enter the market capitalization / floating share ratio of the quarterly report.',
        }, {
          serviceName: 'PERAGARU NIKKEI225',
          link: 'https://nikkei225.peragaru.com',
          description: 'This is a calculating service that the buying demand for stocks decided to be adopted by Nikkei 225 by incorporating them from passive funds. Please enter the market capitalization / floating share ratio of the quarterly report.',
        },
      ];
      this.mainMessageHeight = this.$vuetify.breakpoint.name === 'xs' ? 600 * Math.ceil(this.serviceList.length / 2) : 600;
    },
    goto(link) {
      // eslint-disable-next-line no-restricted-globals
      window.open(link);
    },
  },
};
</script>
