<template>
  <div id="app">

    <header>
      <!-- TODO:レスポンシブなスマホ用メニュー -->
<!--      <b-navbar-->
<!--        toggleable="lg"-->
<!--        type="dark"-->
<!--        fixed="top">-->
      <b-navbar
        toggleable="lg"
        type="light"
        fixed="top">
        <b-container>
          <b-navbar-brand href="#" @click='toHome'></b-navbar-brand>
          <b-navbar-toggle target="navbarNavDropdown"></b-navbar-toggle>
          <b-collapse id="navbarNavDropdown" is-nav>
            <b-navbar-nav class="mr-auto"></b-navbar-nav>
            <b-navbar-nav>
              <b-nav-item
                v-if="!signinStatus"
                @click='doSignin'>
                ログイン
              </b-nav-item>
              <b-nav-item
                v-if="signinStatus"
                @click='goto("/list")'>
                契約企業一覧
              </b-nav-item>
              <b-nav-item
                class="user-menu-sp"
                v-if="signinStatus"
                @click='goto("/user")'>
                アカウント情報
              </b-nav-item>
              <b-nav-item
                class="user-menu-sp"
                v-if="signinStatus"
                @click='goto("/contact")'>
                お問い合わせ
              </b-nav-item>
              <b-nav-item
                class="user-menu-sp"
                v-if="signinStatus"
                @click='doSignout'>
                ログアウト
              </b-nav-item>
              <b-nav-item-dropdown
                v-if="signinStatus"
                class="user-menu"
                text="">
                <b-dropdown-item
                  @click='goto("/user")'>
                  アカウント情報
                </b-dropdown-item>
                <b-dropdown-item
                  @click='goto("/contact")'>
                  お問い合わせ
                </b-dropdown-item>
                <div class="dropdown-divider"></div>
                <b-dropdown-item
                @click='doSignout'>
                  ログアウト
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </b-container>
      </b-navbar>
    </header>

    <b-container fluid id="main-content">
      <router-view />
    </b-container>

    <Footer />
  </div>
</template>

<script>
import Footer from './components/Footer.vue';
import router from './router';
import Singin from './lib/model/user/signin';
import Session from './lib/model/user/session';

export default {
  name: 'App',
  components: {
    Footer,
  },
  data: () => ({
    signinStatus: false,
    menues: [],
    companyListMenu: {
      title: 'Company List',
      link: '/list',
    },
    originalMenues: [
      {
        title: 'About',
        link: '/about',
      },
      {
        title: 'Search',
        link: '/search',
      },
    ],
  }),
  async created() {
    this.menues = JSON.parse(JSON.stringify(this.originalMenues));
    this.signinStatus = await this.checkSigninStatus();
    if (this.signinStatus && !this.menues.some((m) => m.title === 'Company List')) {
      this.menues.push(this.companyListMenu);
    }
  },
  async updated() {
    this.signinStatus = await this.checkSigninStatus();
    if (this.signinStatus && !this.menues.some((m) => m.title === 'Company List')) {
      this.menues.push(this.companyListMenu);
    }
  },
  methods: {
    toHome() {
      router.push({ path: '/' }).catch(() => {});
    },
    async checkSigninStatus() {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return false;
      }
      return true;
    },
    goto(link) {
      // eslint-disable-next-line no-restricted-globals
      router.push({ path: link });
    },
    doSignin() {
      router.push({ path: '/signin' }).catch(() => {});
    },
    doSignup() {
      router.push({ path: '/signup' }).catch(() => {});
    },
    doSignout() {
      Singin.doSignout();
      this.signinStatus = false;
      this.menues = JSON.parse(JSON.stringify(this.originalMenues));
      router.push({ path: '/' }).catch(() => {});
    },
  },
};
</script>
