<template>
  <section>
    <transition name="vloading">
      <div class="loading-wrapper" v-if="isLoading"><div class="loading-icon"></div></div>
    </transition>
    <transition-group name="vloading" tag="div" v-if="!isLoading">
      <div v-for='(value, type) in metricsEveryType' :key='type'>
        <div class="pera-panel graph-panel mt-4">
          <h4>{{ type }}</h4>
          <!-- <MetricsGraph :type='type' :data='value.metrics' :term='value.term' :fiscalEndMonth='fiscalEndMonth'/> -->
          <MetricsGraphEcharts class="mt-3"
            :type='type' :data='value.metrics'
            :term='value.term'
            :fiscalEndMonth='fiscalEndMonth'
            :deficientList='typeWithDeficientList[type]'
            :scrapeInfo='typeWithScrapeInfo[type]'
          />
          <div class="row mt-3">
            <div class="col-sm-12 col-md-6">
              <b-form-group>
                <b-button-group>
                  <b-button
                    class="calc-btn"
                    :variant="durationSelected[type] === 'origin' ? 'info' : null"
                    @click="selectDuration('origin', type)"
                  >
                    Origin
                  </b-button>
                  <b-button
                    class="calc-btn"
                    v-if="!!fiscalEndMonth && showableDuration('quarterly', typeWithScrapeInfo, type)"
                    :variant="durationSelected[type] === 'quarterly' ? 'info' : null"
                    @click="selectDuration('quarterly', type)"
                  >
                    Quarterly
                  </b-button>
                  <b-button
                    class="calc-btn"
                    v-if="showableDuration('monthly', typeWithScrapeInfo, type)"
                    :variant="durationSelected[type] === 'monthly' ? 'info' : null"
                    @click="selectDuration('monthly', type)"
                  >
                    Monthly
                  </b-button>
                  <b-button
                    class="calc-btn"
                    v-if="showableDuration('weekly', typeWithScrapeInfo, type)"
                    :variant="durationSelected[type] === 'weekly' ? 'info' : null"
                    @click="selectDuration('weekly', type)"
                  >
                    Weekly
                  </b-button>
                </b-button-group>

                <b-button-group class="ml-3" v-show="!clickableCalcType(type)">
                  <b-button
                    class="calc-btn"
                    :variant="calcTypeSelected[type] === 'avg' ? 'info' : null"
                    @click="selectCalcType('avg', type)"
                  >
                    Avg
                  </b-button>
                  <b-button
                    class="calc-btn"
                    :variant="calcTypeSelected[type] === 'sum' ? 'info' : null"
                    @click="selectCalcType('sum', type)"
                  >
                    Sum
                  </b-button>
                </b-button-group>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6 text-right">
              <div class="btn-group download" role="group">
                DOWNLOAD:
                <b-link class="mx-2 download-link" v-on:click='downloadCSV(type, value)'>CSV</b-link>
                /
                <b-link class="mx-2 download-link" v-on:click='downloadJSON(type, value)'>JSON</b-link>
              </div>
            </div>
          </div>
          <div class="mt-3" v-if="typeWithScrapeInfo">
            <h4>データシート</h4>
            <div
              v-if="typeWithScrapeInfo[type]"
              class="pera-scrape-info mt-3">
              <dl>
                <div>
                  <dt>取得開始日</dt>
                  <dd v-html='typeWithScrapeInfo[type].startDate'></dd>
                </div>
                <div>
                  <dt>最小粒度</dt>
                  <dd v-html='typeWithScrapeInfo[type].intervalNum + typeWithScrapeInfo[type].intervalUnit'></dd>
                </div>
                <div
                  v-if="insightsByType[type]"
                  class="comment">
                  <dt>備考</dt>
                  <dd v-html='insightsByType[type]'></dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </section>
</template>

<script>
import * as moment from 'moment-timezone';
import { summarize } from '@/lib/service/metrics-summarizer';
import MetricsSetting from '@/lib/model/project/metrics-setting';
// import MetricsGraph from './MetricsGraph.vue';
import MetricsGraphEcharts from './MetricsGraphEcharts.vue';
// import Project from "../lib/model/project/project";
import { timestampToQuarterlyFormat, timestampToFormatDate } from '../lib/helper/time-helper';

export default {
  name: 'ProjectMetricsEcharts',
  components: {
    // MetricsGraph,
    MetricsGraphEcharts,
  },
  props: ['projectName', 'metrics', 'typeWithDeficientList', 'typeWithScrapeInfo', 'fiscalEndMonth'],
  methods: {
    downloadCSV(type, data) {
      const format = 'text/csv';
      const csvData = this.toCSV(data);
      this.download(type, csvData, format);
    },
    downloadJSON(type, data) {
      const format = 'application/json';
      this.download(type, JSON.stringify(data), format);
    },
    download(type, data, format) {
      const link = window.document.createElement('a');
      link.href = URL.createObjectURL(new Blob([data], { type: format }));
      link.download = `${this.projectName}_${type}_metrics_exported_${moment().format('x')}`;
      document.body.appendChild(link);
      link.click();
      console.log(`link: ${link}`);
    },
    toCSV(data) {
      const { metrics } = data.metrics.find((d) => d);
      const header = ['date', 'FY', ...Object.keys(metrics)].join(',');
      const body = data.metrics.reduce((ac, content) => {
        const formattedDate = timestampToFormatDate(content.date, 'YYYY/MM/DD');
        const quarterlyFormat = timestampToQuarterlyFormat(Number(content.date), this.fiscalEndMonth);
        const values = Object.values(content.metrics).join(',');
        return `${ac}\n${[formattedDate, quarterlyFormat, values].join(',')}`;
      }, '');
      return `\ufeff${header}${body}`;
    },
    selectDuration(type, metricsType) {
      this.durationSelected[metricsType] = type;
      if (this.durationSelected[metricsType] === 'origin') {
        this.calcTypeSelected[metricsType] = 'avg';
        this.resetMetrics(metricsType);
      }
      if (this.durationSelected[metricsType] !== 'origin') this.summarize(type, metricsType);
    },
    selectCalcType(type, metricsType) {
      this.calcTypeSelected[metricsType] = type;
      if (this.durationSelected[metricsType] !== 'origin') this.summarize(type, metricsType);
    },
    resetMetrics(metricsType) {
      this.metricsEveryType[metricsType] = {
        metrics: JSON.parse(
          JSON.stringify(this.originMetricsEveryType[metricsType].metrics),
        ),
        term: 'origin',
      };
    },
    summarize(type, metricsType) {
      const metricsSettings = {
        duration: this.durationSelected[metricsType],
        calcType: this.calcTypeSelected[metricsType],
      };
      if (this.durationSelected[metricsType] === 'quarterly' && this.fiscalEndMonth) metricsSettings.fiscalEndMonth = this.fiscalEndMonth;
      this.metricsEveryType[metricsType] = {
        term: this.durationSelected[metricsType],
        metrics: summarize(
          new MetricsSetting(metricsSettings),
          JSON.parse(JSON.stringify(this.originMetricsEveryType[metricsType].metrics)),
        ),
      };
    },
    clickableCalcType(metricsType) {
      return this.durationSelected[metricsType] === 'origin';
    },
    fetchStartDate(typeWithScrapeInfo, type) {
      return typeWithScrapeInfo && typeWithScrapeInfo[type] && typeWithScrapeInfo[type].startDate;
    },
    fetchIntervalNum(typeWithScrapeInfo, type) {
      return typeWithScrapeInfo && typeWithScrapeInfo[type] && typeWithScrapeInfo[type].intervalNum;
    },
    fetchIntervalUnit(typeWithScrapeInfo, type) {
      const intervalUnit = typeWithScrapeInfo && typeWithScrapeInfo[type] && typeWithScrapeInfo[type].intervalUnit;
      if (intervalUnit === 'day') return '日';
      if (intervalUnit === 'week') return '週';
      return 'ヶ月';
    },
    showableDuration(duration, typeWithScrapeInfo, type) {
      if (!typeWithScrapeInfo || !typeWithScrapeInfo[type] || !typeWithScrapeInfo[type].intervalUnit) return true;
      const { intervalUnit } = typeWithScrapeInfo[type];
      if (intervalUnit === 'day') return true;
      if (intervalUnit === 'week') return this.durationOrder[duration] > this.durationOrder.weekly;
      if (intervalUnit === 'month') return this.durationOrder[duration] > this.durationOrder.monthly;
      if (intervalUnit === 'quarter') return this.durationOrder[duration] > this.durationOrder.quarterly;
      return true;
    },
  },
  data: () => ({
    originMetricsEveryType: {},
    metricsEveryType: {
      // key: [],
    },
    insightsByType: {},
    isLoading: true,
    durationSelected: {},
    calcTypeSelected: {},
    // editedDeficientListByType: {},
    durationOrder: {
      daily: 0,
      weekly: 1,
      monthly: 2,
      quarterly: 3,
    },
  }),
  watch: {
    metrics(metrics) {
      // console.log(metrics.length);
      this.metricsEveryType = metrics.reduce((ac, metric) => {
        const params = {
          date: metric.date,
          metrics: metric.metrics,
        };
        if (!ac[metric.type]) {
          // 副作用がここであるのはどうかというのはあるが、今のデータ構造だと一番効率的
          this.insightsByType[metric.type] = !!metric.typeInsights && metric.typeInsights !== 'Null' ? metric.typeInsights : '';
          const matchedList = Array.from(new Set(this.insightsByType[metric.type].match(/((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g)))
            .sort((a, b) => (a.length < b.length ? 1 : -1));
          if (matchedList && matchedList.length) {
            matchedList.forEach((matched) => {
              const escapedMatched = matched.replace('?', '\\?').replace('&', '\\&');
              const regex = new RegExp(`(?!(.*href=|.*\\/a>))${escapedMatched}`, 'g');
              this.insightsByType[metric.type] = this.insightsByType[metric.type].replace(
                regex,
                `<a href=${matched} target="_blank">${matched}</a>`,
              );
            });
          }
          // console.log('typeWithDeficientList', metric, this.typeWithDeficientList);
          // console.log('typeWithScrapeInfo', metric.type, this.typeWithScrapeInfo);
          // this.editedDeficientListByType[metric.type] = metric.deficientList;
          return Object.assign(ac, {
            [metric.type]: {
              metrics: [params],
              term: 'origin',
            },
          });
        }
        ac[metric.type].metrics.push(params);
        return ac;
      }, {});
      this.originMetricsEveryType = JSON.parse(JSON.stringify(this.metricsEveryType));
      Object.keys(this.metricsEveryType).forEach((key) => {
        this.durationSelected = Object.assign(JSON.parse(JSON.stringify(this.durationSelected)), { [key]: 'origin' });
        this.calcTypeSelected = Object.assign(JSON.parse(JSON.stringify(this.calcTypeSelected)), { [key]: 'avg' });
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    // metricsEveryType(parameters) {
    //   console.log('metricsEveryType', Object.keys(parameters));
    // },
  },
};
</script>

<style scoped>

</style>
