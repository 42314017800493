<template>
  <UserSign
    :type="type"
  />
</template>

<script>
import UserSign from '../components/UserSign.vue';
import Init from './Init.vue';

export default {
  name: 'Signin',
  mixins: [
    Init,
  ],
  components: {
    UserSign,
  },
  data: () => ({
    type: 'Signin',
  }),
};
</script>
