<template>
  <div class="pera-panel info-panel">
    <h5>適時開示情報一覧</h5>
    <ul class="list-group list-group-flush">
    <template v-for="disclosure in disclosureList">
      <li class="list-group-item" :key="disclosure.id">
        <a href="" @click="newWindow(disclosure.link)">{{ formatDisplay(disclosure) }}</a>
      </li>
    </template>
    </ul>
  </div>
</template>

<script>
import * as moment from 'moment';
import Disclosure from '../../lib/model/stock/disclosure';
import * as Http from '../../lib/http/http';

export default {
  name: 'DisclosureList',
  props: [
    'securityCode',
  ],
  components: {},
  data: () => ({
    disclosureList: [],
  }),
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      if (!this.securityCode || this.securityCode === '0') return;
      const list = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_STOCK_DOMAIN}/${process.env.VUE_APP_BACKEND_STOCK_VERSION}/disclosure/list/${this.securityCode}`,
        // this.sessionToken,
      ).catch((e) => {
        console.error(e);
        return [];
      });

      this.disclosureList = list
        .map((d) => new Disclosure(d))
        .sort((a, b) => (a.date < b.date ? 1 : -1));
    },
    formatDisplay(disclosure) {
      return `${this.formatDate(disclosure.date)} - 【${disclosure.securityCode}】${disclosure.name} - ${disclosure.title}`;
    },
    formatDate(date) {
      return moment(date).format('MM/DD HH:mm');
    },
    newWindow(link) {
      window.open(link);
    },
    key(d) {
      return `${d.id}_${d.date}`;
    },
  },
  computed: {},
  watch: {
    async securityCode() {
      await this.init();
    },
  },
};
</script>

<style scoped>

</style>
