<template>
  <b-container>
    <div class="pera-panel terms">
      <h2>利用規約</h2>
      <ol>
        <li>「PERAGARU」（以下、本サービスという）は株式会社hands（以下、handsという）が提供するビジネス情報サービスで、顧客は本サービスに係るデータ、画像、映像、ソフトウエア等の知的所有権など全ての権利は、handsまたはhandsへの情報提供者に帰属することに同意する。
顧客へのサービスの質を向上させるために、handsがプログラム、通信手段、情報内容を変更することがあることに同意する。</li>
        <li>顧客へのサービスの質を向上させるために、handsがプログラム、通信手段、情報内容を変更することがあることに同意する。</li>
        <li>
          顧客は、本サービスを顧客の組織内に於ける内部利用に限るものとし、本サービスに係るデータ、マニュアル等またはそのコピーを有料、無料を問わず第三者に提供しない。また、顧客は、別途書面または本サービス画面でhandsに許可された場合を除き、いかなる形態でも、以下の行為をしてはならない。
          <ol>
            <li>本サービスで提供される情報をダウンロードまたは蓄積すること（ただし、本一般条項を遵守した利用の際に印刷した情報の保存を除く。）。</li>
            <li>本サービスで提供される情報を利用した商品を生産すること。</li>
            <li>本サービスで提供される情報またはそれを翻訳・翻案したものを新聞、雑誌、情報サービスなどの各種メディアおよび各種サイト、メールサービス等に転載、投稿すること。</li>
          </ol>
        </li>
        <li>顧客はPERAGARU専用メニュー内でダウンロードしたファイル（以下、ダウンロードファイル）に関する一切の資料の全部もしくは一部またはその複製物を第三者（親会社、子会社、関連会社を含む）に配布、譲渡、刊行、伝達その他有償無償を問わず、また方法の如何を問わず利用させない。</li>
        <li>顧客の本サービス利用にあたっての銘柄申し込みは別紙PERAGARU利用申請書の通りとする。</li>
        <li>顧客は、本サービスを利用するために必要な機器およびソフトウエアを用意または購入することに同意する。</li>
        <li>顧客が第6条に従って必要なソフトウエアを導入していなかった場合に発生する同ソフトウエアの著作権にかかわる問題については顧客が責任をもって解決し、handsには一切迷惑をかけない。</li>
        <li>顧客は、契約期間の合計金額および情報利用料金の請求を受けた1ヵ月以内に、handsの指定する銀行口座にその料金全額を振り込み支払う。若しくは顧客の預金口座からの自動振替によるものとする。なお、振込手数料は顧客の負担とする。</li>
        <li>handsは顧客に対して１ヵ月前までに文書または本サービスの画面で通知したうえで改定することができる。顧客が改定日の１５日前までに当該改定に応じない旨文書でhandsに通知したときは、当該改定日を以って本契約は終了する。</li>
        <li>本契約の有効期限は利用開始日から1ヶ月経過後の月末日までとするが、期間満了日の30日前までに顧客、handsのいずれからも文書による解約の申し出がないときは１年間延長するものとし、以後も同様とする。ただし、顧客が手形および小切手の不渡りを出したとき、破産、民事再生、会社更生の手続き開始の決定がなされた場合、その他これに準ずる場合は、本契約は自動的に終了する。また顧客が本契約に規定された義務のうち、いずれか１つにでも違反した場合は、handsは顧客へ通知することにより（ただし、第15条に違反した場合は通知することなく）本契約を終了させることができる。</li>
        <li>顧客は、本契約の中途解約を希望する場合、解約日の１ヶ月前までに書面により解約日をhandsに対して通知し、かつ、有効期限までの残存期間の月基本料金額の合計額を一括して支払うことにより、本契約を中途解約することができる。</li>
        <li>本サービスは、handsが信頼できると考える筋から取得されるが、その正確性、完全性は保証されない。handsは、本サービスの誤りを訂正するため自己の費用で合理的な努力を尽すが、handsの責任はそのような訂正の費用に限定される。</li>
        <li>handsは、本サービスの提供、本サービスの中断、本サービス中の事故等によって、直接または間接的に生じた顧客またはそれ以外の第三者の損害については、その内容、方法の如何にかかわらず賠償の責任を負わない。また、顧客は本サービスに基づく第三者との損害賠償請求などの訴訟にhandsを引き込まないことに同意する。</li>
        <li>顧客は本サービスを利用することにより得た個人情報について、プライバシーの尊重と個人情報の保護に十分留意し、適切な方法で利用し、管理することに同意する。</li>
        <li>
          顧客または顧客の所属する企業もしくはその役員は、次の各号のいずれにも該当せず、次の各号のいずれにも関与していないことを表明し、保証する。
          <ol>
            <li>暴力団、暴力団員、暴力団関係企業</li>
            <li>前号以外で、反社会的勢力に該当する者。反社会的勢力とは、暴力、威力と詐欺的手法を駆使して経済的利益を追求し、又は市民社会の秩序や安全に脅威を与える集団又は個人をいう。</li>
          </ol>
        </li>
        <li>機器の故障、回線の異常、電力の不安定、火災その他偶発事故、その他合理的管理を超えて発生した諸原因によって顧客が本サービスを利用できなかった場合、handsは本契約の不履行の責任は負わない。</li>
        <li>顧客はhandsの書面による同意なしに本契約に基づく権利、義務を譲渡することはできない。</li>
        <li>本契約に関する一切の紛争の専属的管轄裁判所は東京地方裁判所とする。</li>
        <li>本契約に定めていない事項または本契約に関する疑義が生じた場合、当事者は信義誠実の原則に従って協議するものとする。</li>
      </ol>
      <p class="mt-5 text-right">2019年9月1日改定</p>
    </div>
  </b-container>
</template>

<script>
import Init from './Init.vue';

export default {
  name: 'Terms',
  mixins: [
    Init,
  ],
  components: {
  },
  data: () => ({}),
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>

</style>
