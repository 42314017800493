<template>
  <v-card v-on:click="toDetail(projectInfo.companyName)"
          style="cursor: pointer"
          color="primary"
          height="100%">

    <v-card-title primary-title>
      <div>
          <h6 class="headline mb-0">{{ display }}</h6>
      </div>
    </v-card-title>

    <v-card-text class="">
    </v-card-text>

    <v-divider dark></v-divider>

    <v-card-text>
      Security Code:
      <v-divider
        light
        vertical
        style="color: black"></v-divider>
      <span class="font-weight-bold">
        {{ projectInfo.securityCode }}
      </span>
    </v-card-text>

    <v-divider light></v-divider>

    <v-card-text>
      URL:
      <v-divider
        light
        vertical
        style="color: black"></v-divider>
      <span class="font-weight-bold">
        {{ displayUrl }}
      </span>

    </v-card-text>

  </v-card>
</template>

<script>
import router from '../router';
import { keyCheck } from '../lib/helper/object-helper';

export default {
  name: 'SearchedList',
  props: [
    'projectInfo',
  ],
  data: () => ({}),
  created() {},
  mounted() {},
  methods: {
    toDetail(cn) {
      router.push({ path: `/public/company/detail/${cn}` });
    },
  },
  computed: {
    display() {
      if (!this.projectInfo || !this.projectInfo.companyName) return '';
      return !!keyCheck(this.projectInfo, 'companyDisplayName') && !!this.projectInfo.companyDisplayName
        ? this.projectInfo.companyDisplayName : this.projectInfo.companyName;
    },
    displayUrl() {
      if (!this.projectInfo || !this.projectInfo.companyUrl) return '-';
      return this.projectInfo.companyUrl !== 'Null' ? this.projectInfo.companyUrl : '-';
    },
  },
  watch: {},
};
</script>

<style scoped>

</style>
