<template>
  <b-container>
    <ProjectList />
    <br />
    <br />
    <IndexList />
  </b-container>
</template>

<script>
import Init from './Init.vue';
import ProjectList from '../components/ProjectList.vue';
import IndexList from '../components/IndexList.vue';

export default {
  name: 'List',
  mixins: [
    Init,
  ],
  components: {
    ProjectList,
    IndexList,
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
  /*@import '../assets/scss/custom';*/
  /*@import '~bootstrap/scss/bootstrap.scss';*/
  .alpha { background-color: purple; }

.pera-panel {
  background-color: #303030;
  padding: 1rem 2rem;
}
</style>
