import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/es5/util/colors';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify, {
  theme: {
    primary: '#009688',
    secondary: '#8bc34a',
    accent: '#03a9f4',
    error: colors.red.darken4,
    warning: '#ffeb3b',
    info: '#3f51b5',
    success: '#cddc39',
  },
  options: {
    customProperties: true,
  },
  iconfont: 'mdi',
});
