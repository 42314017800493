<template>
  <div class="pera-brand">
    <a class="link-item" v-on:click="toDetail(index.indexName)">
<!--      <b-row class="border-bottom border-dark">-->
      <b-row class="border-bottom border-light">
        <b-col cols="12" md="4" class="brand-name">
          <label>指数名</label>
          <h4>{{ displayName }}</h4>
        </b-col>
        <b-col cols="12" md="8" class="brand-code">
          <label>説明</label>
          <p>{{ description }}</p>
        </b-col>
      </b-row>
    </a>
  </div>
</template>

<script>
import router from '../router';

export default {
  name: 'Index',
  props: [
    'index',
  ],
  created() {},
  mounted() {},
  methods: {
    toDetail(indexName) {
      router.push({ path: `/index/detail/${indexName}` });
    },
  },
  computed: {
    displayName() {
      return this.index.indexDisplayName
        ? this.index.indexDisplayName : this.index.indexName;
    },
    description() {
      return this.index.description;
    },
  },
};
</script>

<style scoped>
</style>
