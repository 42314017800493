<template>
  <v-container fluid>
    <v-layout
      align-center
      justify-center
      row
      wrap
    >

      <v-flex xs12>
        <v-layout
          align-center
          justify-center
          row
          wrap
        >
          <v-flex xs6>
            <v-alert
              v-if="isSuccess"
              :value="true"
              dismissible
              type="success"
              icon="new_releases"
            >
              Successfully changed.
            </v-alert>
            <v-alert
              v-if="isError"
              :value="true"
              dismissible
              type="error"
              icon="warning"
            >
              Error occurred. Please contact us from Inquiry Form.
              <a :href="inquiryFormLink">
                Go to inquiry form
              </a>
            </v-alert>
            <v-alert
              v-if="isKnownError"
              :value="true"
              dismissible
              type="error"
              icon="warning"
            >
              {{ errorMessage }}
            </v-alert>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex
        xs6
      >
        <v-card>
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">Your account</h3>
            </div>
          </v-card-title>
          <v-layout
            pa-4
            column
          >
            <v-text-field
              label="Username"
              v-model="currentUser.name"
              readonly
            >
            </v-text-field>
            <v-text-field
              label="Email Address"
              type="email"
              v-model="currentUser.email"
              readonly
            >
            </v-text-field>
            <v-text-field
              v-if="phase === 'CHANGE'"
              label="Current Password"
              type="password"
              v-model="currentPassword"
              :error-messages="currentPasswordErrorMsg"
            >
            </v-text-field>
            <v-text-field
              v-if="phase === 'CHANGE'"
              label="New Password"
              type="password"
              v-model="password"
              :rules="[rules.password]"
              :error-messages="passwordErrorMsg"
            >
            </v-text-field>
            <v-text-field
              v-if="phase === 'CHANGE'"
              label="Confirm password"
              type="password"
              v-model="confirmedPassword"
              @focusout="checkConfirmedPassword"
              :error-messages="confirmedPasswordErrorMsg"
            >
            </v-text-field>
          </v-layout>

          <v-card-actions>
            <v-layout
              align-end
              justify-end
              row
              fill-height
            >
              <v-btn
                v-if="phase !== 'CHANGE'"
                style="color: #fff;background-color: #007bff;border-color: #007bff;"
                @click="toChangePassword"
              >
                Change Password
              </v-btn>
              <v-btn
                v-if="phase === 'CHANGE'"
                style="color: #fff;background-color: #6c757d;border-color: #6c757d;"
                @click="back"
              >
                Back
              </v-btn>
              <v-btn
                v-if="phase === 'CHANGE'"
                style="color: #fff;background-color: #007bff;border-color: #007bff;"
                @click="doChangePassword"
              >
                Change
              </v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>

</template>

<script>
import Signin from '../../lib/model/user/signin';
import Session from '../../lib/model/user/session';
import * as Http from '../../lib/http/http';
import User from '../../lib/model/user/user';

export default {
  name: 'UserSetting',
  components: {
  },
  data: () => ({
    sessionToken: {},
    phase: 'CHECK',
    isSuccess: false,
    isError: false,
    isKnownError: false,
    errorMessage: '',
    inquiryFormLink: process.env.VUE_APP_GOOGLE_FORM_LINK,
    currentUser: {},
    currentPassword: '',
    currentPasswordErrorMsg: '',
    password: '',
    passwordErrorMsg: '',
    confirmedPassword: '',
    confirmedPasswordErrorMsg: '',
    signInStatus: {},
    rules: {
      password: (v) => !!(v || '').match(/^(?=[!-@[-`{-~A-Z]{0,}[a-z])(?=[!-@[-`{-~a-z]{0,}[A-Z])(?=[!-/:-~]{0,}\d)(?=[a-zA-Z\d]{0,}[!-/:-@[-`{-~])[!-~]{10,}$/)
        || 'Password must contain an upper and lower case letter, a numeric character, and a special character. And Password must be between 8 and 100 characters',
    },
  }),
  async created() {
    await this.init();
  },
  mounted() {},
  methods: {
    async init() {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return;
      }
      this.sessionToken = token;
      const user = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/user/current`,
        this.sessionToken,
      );
      this.currentUser = new User(user);
    },
    toChangePassword() {
      this.phase = 'CHANGE';
    },
    back() {
      this.phase = 'CHECK';
      this.isError = false;
      this.isKnownError = false;
      this.errorMessage = '';
    },
    async doChangePassword() {
      let invalidFlag = false;
      this.currentPasswordErrorMsg = '';
      this.passwordErrorMsg = '';
      this.confirmedPasswordErrorMsg = '';
      if (!this.currentPassword) {
        invalidFlag = true;
        this.currentPasswordErrorMsg = 'You must input Current Password.';
      }
      if (!this.password) {
        invalidFlag = true;
        this.passwordErrorMsg = 'You must input Password.';
      }
      if (!this.password.match(/^(?=[!-@[-`{-~]{0,99}[a-z])(?=[!-/:-~]{0,99}\d)(?=[a-z\d]{0,99}[!-/:-@[-`{-~])[!-~]{8,100}$/i)) {
        invalidFlag = true;
        this.passwordErrorMsg = 'Password must contain an upper and lower case letter, a numeric character, and a special character. And Password must be between 8 and 100 characters';
      }
      if (this.password !== this.confirmedPassword) {
        invalidFlag = true;
        this.confirmedPasswordErrorMsg = 'Confirm Password must be same with Password.';
      }
      if (invalidFlag) return;
      const setStatus = await Signin.changePassword(this.currentPassword, this.password);
      if (!setStatus) {
        this.isError = true;
        return;
      }
      this.signInStatus = setStatus;
      if (!setStatus.success()) {
        this.isKnownError = true;
        this.errorMessage = setStatus.errMessage;
        return;
      }
      this.isSuccess = true;
      setTimeout(() => {
        this.isSuccess = false;
      }, 10000);
      this.phase = 'CHECK';
    },
    checkConfirmedPassword() {
      this.confirmedPasswordErrorMsg = '';
      if (this.confirmedPassword !== this.password) this.confirmedPasswordErrorMsg = 'Confirm Password must be same with Password.';
    },
  },
};
</script>
<style scoped>
</style>
