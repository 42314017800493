<template>
  <v-container
    grid-list-md
    text-xs-center
  >
    <v-layout
      row
      text-xs-left
      wrap
    >
      <v-flex xs12>
        <span class='headline'>
          <h2>Charge Plan</h2>
        </span>
        <span class='subheading'>
          Please contact us from the inquiry form
          <a class='subheading' @click="gotoInquiryForm" style="cursor: pointer;">here</a>
          for use or questions.
          <br />
          <a class='subheading' @click="gotoInquiryForm" style="cursor: pointer;">
            Go to inquiry form
          </a>
        </span>
        <br />
      </v-flex>

      <v-divider
        class="mx-2 inset"
      />

      <v-flex xs12>
        <v-img
          src="charge-plans.png"
          class="my-3"
          contain
        />

      </v-flex>

    </v-layout>
  </v-container>
</template>

<script>
import Init from './Init.vue';

export default {
  components: {},
  name: 'ChargePlan',
  mixins: [
    Init,
  ],
  data: () => ({}),
  methods: {
    gotoInquiryForm() {
      window.open(process.env.VUE_APP_GOOGLE_FORM_LINK);
    },
  },
  created() {},
};
</script>
