<template>
  <div class="pera-brand">
    <a class="link-item" v-on:click="toDetail(project.companyName)">
      <b-row>
        <b-col cols="12" md="2" class="brand-logo">
          <img class="img-fluid" :src=displayImage>
        </b-col>
        <b-col cols="12" md="4" class="brand-name">
          <label>銘柄名</label>
          <h4>{{ displayName }}</h4>
        </b-col>
        <b-col cols="4" md="2" class="brand-code">
          <label>銘柄コード</label>
          <p>{{ displayCode }}</p>
        </b-col>
        <b-col cols="8" md="4" class="brand-url">
          <label>URL</label>
          <button @click="gotoCompanyProfile"
              type="button"
              class="btn btn-link">
            {{ displayUrl }}
          </button>
        </b-col>
      </b-row>
    </a>
  </div>
</template>

<script>
import router from '../router';

export default {
  name: 'Project',
  props: [
    'project',
  ],
  created() {},
  mounted() {},
  methods: {
    toDetail(cn) {
      // router.push({ path: `/detail/${cn}`, query: { project: cn } });
      router.push({ path: `/company/detail/${cn}` });
    },
    gotoCompanyProfile(event) {
      if (!!this.project.companyUrl && this.project.companyUrl !== 'Null') {
        window.open(this.project.companyUrl);
        event.stopPropagation();
      }
    },
  },
  computed: {
    displayImage() {
      return this.project.companyImageUrl && this.project.companyImageUrl !== 'Null' ? this.project.companyImageUrl : '/in-preparation-img.png';
    },
    displayName() {
      return this.project.companyDisplayName
        ? this.project.companyDisplayName : this.project.companyName;
    },
    displayCode() {
      return this.project.securityCode;
    },
    displayUrl() {
      return !!this.project.companyUrl && this.project.companyUrl !== 'Null' ? this.project.companyUrl : '-';
    },
  },
};
</script>

<style scoped>
</style>
