<template>
  <b-container>
    <span class="loading-icon">
    </span>
    <ProjectInfo :projectInfo='packageInfo' />
    <MetricsEcharts
      v-show="packageMetricsExisting"
      :projectName='packageProjectName'
      :metrics='packageMetrics'
      :typeWithDeficientList='packageTypeWithDeficientList'
      :typeWithScrapeInfo='packageTypeWithScrapeInfo'
      :fiscalEndMonth='packageInfo.fiscalEndMonth'
    />
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import * as Http from '../lib/http/http';
import PackageInfo from '../lib/model/package/package-info';
import Session from '../lib/model/user/session';
import Init from './Init.vue';
import ProjectInfo from '../components/ProjectInfo.vue';
import MetricsEcharts from '../components/MetricsEcharts.vue';

export default {
  name: 'ProjectDetail',
  mixins: [
    Init,
  ],
  components: {
    ProjectInfo,
    MetricsEcharts,
  },
  data: () => ({
    sessionToken: {},
    packageProjectName: '',
    packageInfo: new PackageInfo(),
    metrics: [],
    typeWithDeficientList: {},
    typeWithScrapeInfo: {},
    packageMetricsExisting: false,
    packageMetrics: [],
    packageTypeWithDeficientList: {},
    packageTypeWithScrapeInfo: {},
  }),
  methods: {
    ...mapActions({
      getMetricsFromLocal: 'Metrics/getMetricsFromLocal',
    }),
    async init() {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return;
      }
      this.sessionToken = token;
      await Promise.all([
        this.fetchPackageInfo(),
        this.fetchPackageMetrics(),
      ]);
    },
    async fetchPackageInfo() {
      this.packageInfo = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_VERSION}/package-project-metrics/info/${this.packageProjectName}`,
        this.sessionToken,
      )
        .then((p) => new PackageInfo(p))
        .catch((e) => {
          console.error(e);
          return [];
        });
      console.log(this.packageInfo);
    },
    async fetchPackageMetrics() {
      // APIバージョンを並行させる為にハードコーディング
      // 本番リリース後にAPIバージョンを${process.env.VUE_APP_BACKEND_VERSION}に書き換える事
      const { metrics, typeWithDeficientList, typeWithScrapeInfo } = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/package-project-metrics/translated/${this.packageProjectName}`,
        this.sessionToken,
      ).catch((e) => {
        console.error(e);
        return [];
      });
      if (!metrics
        || !Array.isArray(metrics)
        || !metrics.length) {
        return;
      }
      this.packageMetricsExisting = true;
      this.packageMetrics = metrics;
      this.packageTypeWithDeficientList = typeWithDeficientList;
      this.packageTypeWithScrapeInfo = typeWithScrapeInfo;
    },
  },
  async created() {
    this.packageProjectName = decodeURIComponent(this.$route.params.packageProjectName);
    await this.fetchMetricsFromLocal();
  },
  async mounted() {
    await this.init();
  },
};
</script>
