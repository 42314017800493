<template>
  <b-container>
    <div class="panel-form">
      <div class="panel-form-content">
        <div class="text-center">
          <h5 class="mb-4">メールアドレス認証</h5>
        </div>
        <b-form>
          <b-form-group>
            <b-form-input
              type="text"
              v-model="username"
              id="username"
              class="form-control-lg"
              placeholder="お名前"
              :state="usernameValidation"></b-form-input>
            <b-form-invalid-feedback :state="usernameValidation">※必須です</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <b-form-input
              type="text"
              v-model="code"
              id="code"
              class="form-control-lg"
              placeholder="認証コード"
              :state='codeValidation'></b-form-input>
            <b-form-invalid-feedback :state="codeValidation">※必須です</b-form-invalid-feedback>
          </b-form-group>
          <div>
            <b-button
              v-on:click="doSubmit"
              :disabled="isSending"
              class="btn btn-primary btn-block execute-btn">認証する</b-button>
          </div>
        </b-form>
      </div>
    </div>
  </b-container>
</template>

<script>
import * as Analytics from '../lib/infrastructure/google/analytics';

import Signup from '../lib/model/user/signup';
import router from '../router';

import Init from './Init.vue';

export default {
  name: 'Verify',
  mixins: [
    Init,
  ],
  components: {},
  data: () => ({
    isSending: false,
    isSubmited: false,
    username: '',
    code: '',
  }),
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      if (!this.$route.params.username) return;
      this.username = decodeURIComponent(this.$route.params.username);
    },
    showMsgModal(title, msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: `${title}`,
        size: 'mid',
        buttonSize: 'mid',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    showCompleteModal(title, msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: `${title}`,
        size: 'mid',
        buttonSize: 'mid',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      }).then(() => {
        // 閉じたらログインに移動
        router.push({ path: '/signin' });
      });
    },
    doSubmit() {
      this.isSubmited = true;
      if (this.usernameValidation && this.codeValidation) {
        this.verify();
      }
    },
    async verify() {
      this.isSending = true;
      const res = await Signup.confirmRegistration({ username: this.username, code: this.code });
      if (!res) {
        this.isSending = false;
        this.showMsgModal('エラー', 'エラーが発生しました。\nお手数ですがお問い合わせよりご連絡ください。');
        return;
      }
      this.isSending = false;
      Analytics.sendVerifyEvent();
      this.showCompleteModal('認証完了', 'メールアドレスの認証が完了しました。「OK」を押すとログイン画面に移動します。');
    },
  },
  computed: {
    usernameValidation() {
      return this.isSubmited ? this.username !== '' : null;
    },
    codeValidation() {
      return this.isSubmited ? this.code !== '' : null;
    },
  },
};
</script>

<style scoped>

</style>
