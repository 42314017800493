<!-- モザイクグラフ（画像）表示用のテンプレート -->
<template>
  <div class="blur-parent">
    <img src="/blur.png" class="w-100">
    <p class="blur-text">追加でご契約が必要なデータです。</p>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'MetricsBlurGraph',
  data: () => ({}),
  methods: {},
};
</script>
