import Vue from 'vue';
import VueRouter from 'vue-router';
import { setUserId } from '@/lib/infrastructure/google/analytics';
import Search from '../views/public/Search.vue';
import Signin from '../views/Signin.vue';
import ForgetPassword from '../views/ForgetPassword.vue';
import Signup from '../views/Signup.vue';
import Verify from '../views/Verify.vue';
import About from '../views/About.vue';
import User from '../views/User.vue';
import RelatedService from '../views/RelatedService.vue';
import List from '../views/List.vue';
import ProjectDetailEcharts from '../views/ProjectDetailEcharts.vue';
import IndexDetail from '../views/IndexDetail.vue';
import PackageDetail from '../views/PackageDetail.vue';
import SearchedResult from '../views/public/SearchedResult.vue';
import ChargePlan from '../views/ChargePlan.vue';
import ContactForm from '../views/ContactForm.vue';
import Terms from '../views/Terms.vue';
import Cinecon from '../views/package/Cinecon.vue';
import Tvmeta from '../views/package/Tvmeta.vue';

import Login from '../lib/model/user/signin';

import * as Crypto from '../lib/helper/crypto-helper';

Vue.use(VueRouter);

// const sendAnalytics = async (path) => {
//   const encryptedUserName = router.app.$store.getters['Login/getLoginUser'];
//   sendTransitionEvent(encryptedUserName, path);
// };

const sendAnalytics = async (cognitoUser) => {
  const { email } = cognitoUser.idToken.payload;
  const userId = await Crypto.sha256(email);
  setUserId(userId);
};

const singinCheck = async (to, next, ngPath) => {
  const cognitoUser = await Login.checkValid();
  if (cognitoUser) {
    sendAnalytics(cognitoUser);
    next();
  } else {
    next({ path: ngPath });
  }
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '',
      component: About,
      beforeEnter: async (to, from, next) => {
        const cognitoUser = await Login.checkValid();
        if (cognitoUser) {
          sendAnalytics(cognitoUser);
          next({ path: '/list' });
        } else {
          next();
        }
      },
    },
    {
      path: '/cinecon',
      name: 'cinecon',
      component: Cinecon,
    },
    {
      path: '/tvmeta',
      name: 'tvmeta',
      component: Tvmeta,
    },
    {
      path: '/related/services',
      name: 'relatedServices',
      component: RelatedService,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/search',
      name: 'search',
      component: Search,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/signin',
      name: 'signin',
      component: Signin,
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
    },
    {
      path: '/forget/password',
      name: 'forgetPassword',
      component: ForgetPassword,
    },
    {
      path: '/verify',
      name: 'verify',
      component: Verify,
    },
    {
      path: '/user',
      name: 'user',
      component: User,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/list',
      name: 'list',
      component: List,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/company/detail/:projectName',
      name: 'detail',
      component: ProjectDetailEcharts,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/company/:securityCode',
      name: 'result',
      component: SearchedResult,
    },
    {
      path: '/index/detail/:index',
      name: 'indexDetail',
      component: IndexDetail,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/package/detail/:packageProjectName',
      name: 'packageProjectDetail',
      component: PackageDetail,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/plan',
      name: 'chargePlan',
      component: ChargePlan,
      beforeEnter: async (to, from, next) => {
        await singinCheck(to, next, '/signin');
      },
    },
    {
      path: '/contact',
      name: 'contactForm',
      component: ContactForm,
      props: true,
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms,
    },
  ],
});

export default router;
