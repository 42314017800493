<template>
  <section>
    <transition name="vloading">
      <div class="loading-wrapper" v-if="isLoading"><div class="loading-icon"></div></div>
    </transition>
    <transition-group name="vloading" tag="div" v-if="!isLoading">
      <div v-for='(value, type) in metricsEveryType' :key='type'>
        <div class="pera-panel graph-panel mt-4">
          <h4>{{ type }}</h4>
          <MetricsGraphEcharts :type='type' :data='value'/>
          <div class="row mt-3">
            <div class="col-sm-12 col-md-6">
              <b-form-group>
                <b-button-group>
                  <b-button
                    :variant="durationSelected[type] === 'origin' ? 'info' : null"
                    @click="selectDuration('origin', type)"
                  >
                    Origin
                  </b-button>
                  <b-button
                    :variant="durationSelected[type] === 'monthly' ? 'info' : null"
                    @click="selectDuration('monthly', type)"
                  >
                    Monthly
                  </b-button>
                  <b-button
                    :variant="durationSelected[type] === 'weekly' ? 'info' : null"
                    @click="selectDuration('weekly', type)"
                  >
                    Weekly
                  </b-button>
                </b-button-group>

                <b-button-group class="ml-3">
                  <b-button
                    :variant="calcTypeSelected[type] === 'origin' ? 'info' : null"
                    @click="selectCalcType('origin', type)"
                  >
                    Origin
                  </b-button>
                  <b-button
                    :variant="calcTypeSelected[type] === 'avg' ? 'info' : null"
                    @click="selectCalcType('avg', type)"
                  >
                    Avg
                  </b-button>
                  <b-button
                    :variant="calcTypeSelected[type] === 'sum' ? 'info' : null"
                    @click="selectCalcType('sum', type)"
                  >
                    Sum
                  </b-button>
                </b-button-group>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6 text-right">
              <div class="btn-group download" role="group">
                DOWNLOAD:
                <b-link class="mx-2 download-link" v-on:click='downloadCSV(type, value)'>CSV</b-link>
                /
                <b-link class="mx-2 download-link" v-on:click='downloadJSON(type, value)'>JSON</b-link>
              </div>
            </div>
          </div>
          <div
            v-if="insightsByType[type]"
            class="pera-comment mt-3">
            <h5>所見</h5>
            <p class="insights" >{{ insightsByType[type] }}</p>
          </div>
        </div>
      </div>
    </transition-group>
  </section>
</template>

<script>
import * as moment from 'moment-timezone';
import { summarize } from '@/lib/service/metrics-summarizer';
import MetricsSetting from '@/lib/model/project/metrics-setting';
import MetricsGraphEcharts from './MetricsGraphEcharts.vue';
import { timestampToFormatDate } from '../lib/helper/time-helper';
// import MetricsGraph from './MetricsGraph.vue';

export default {
  name: 'IndexMetrics',
  components: {
    MetricsGraphEcharts,
  },
  props: ['indexName', 'metrics'],
  methods: {
    downloadCSV(type, data) {
      const format = 'text/csv';
      const csvData = this.toCSV(data);
      this.download(type, csvData, format);
    },
    downloadJSON(type, data) {
      const format = 'application/json';
      this.download(type, JSON.stringify(data), format);
    },
    download(type, data, format) {
      const link = window.document.createElement('a');
      link.href = URL.createObjectURL(new Blob([data], { type: format }));
      link.download = `${this.indexName}_${type}_metrics_exported_${moment().format('x')}`;
      document.body.appendChild(link);
      link.click();
      console.log(`link: ${link}`);
    },
    toCSV(data) {
      const header = ['date', ...Object.keys(data.find((d) => d).metrics)].join(',');
      const body = data.reduce((ac, content) => `${ac}\n${[timestampToFormatDate(content.date, 'YYYY/MM/DD'), ...Object.values(content.metrics)].join(',')}`, '');
      return `\ufeff${header}${body}`;
    },
    selectDuration(type, metricsType) {
      this.durationSelected[metricsType] = type;
      if (this.durationSelected[metricsType] === 'origin') {
        this.calcTypeSelected[metricsType] = 'origin';
        this.resetMetrics(metricsType);
      }
      if (this.durationSelected[metricsType] !== 'origin' && this.calcTypeSelected[metricsType] !== 'origin') this.summarize(type, metricsType);
    },
    selectCalcType(type, metricsType) {
      this.calcTypeSelected[metricsType] = type;
      if (this.calcTypeSelected[metricsType] === 'origin') {
        this.durationSelected[metricsType] = 'origin';
        this.resetMetrics(metricsType);
      }
      if (this.durationSelected[metricsType] !== 'origin' && this.calcTypeSelected[metricsType] !== 'origin') this.summarize(type, metricsType);
    },
    resetMetrics(metricsType) {
      this.metricsEveryType[metricsType] = JSON.parse(JSON.stringify(this.originMetricsEveryType[metricsType]));
    },
    summarize(type, metricsType) {
      this.metricsEveryType[metricsType] = summarize(
        new MetricsSetting({ duration: this.durationSelected[metricsType], calcType: this.calcTypeSelected[metricsType] }),
        JSON.parse(JSON.stringify(this.originMetricsEveryType[metricsType])),
      );
    },
  },
  data: () => ({
    originMetricsEveryType: {},
    metricsEveryType: {
      // key: [],
    },
    insightsByType: {},
    isLoading: true,
    durationSelected: {},
    calcTypeSelected: {},
  }),
  watch: {
    metrics(metrics) {
      // console.log(metrics.length);
      this.metricsEveryType = metrics.reduce((ac, metric) => {
        const params = {
          date: metric.date,
          metrics: metric.metrics,
        };
        if (!ac[metric.type]) {
          // 副作用がここであるのはどうかというのはあるが、今のデータ構造だと一番効率的
          this.insightsByType[metric.type] = metric.typeInsights !== 'Null' ? metric.typeInsights : '';
          return Object.assign(ac, {
            [metric.type]: [params],
          });
        }
        ac[metric.type].push(params);
        return ac;
      }, {});
      this.originMetricsEveryType = JSON.parse(JSON.stringify(this.metricsEveryType));
      Object.keys(this.metricsEveryType).forEach((key) => {
        this.durationSelected = Object.assign(JSON.parse(JSON.stringify(this.durationSelected)), { [key]: 'origin' });
        this.calcTypeSelected = Object.assign(JSON.parse(JSON.stringify(this.calcTypeSelected)), { [key]: 'origin' });
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
  },
};
</script>

<style scoped>

</style>
