<template>
  <section>
    <transition name="vloading">
      <div class="loading-wrapper" v-if="isLoading"><div class="loading-icon"></div></div>
    </transition>
    <transition-group name="vloading" tag="div" v-if="!isLoading">
      <div v-for='type in metricTypes' :key='type.typeKey'>
        <div class="pera-panel graph-panel mt-4">
          <h4>{{ type.typeDescription }}</h4>
          <MetricsBlurGraph class="mt-3" />
          <div class="mt-3">
            <h4>データシート</h4>
            <div
              v-if="type"
              class="pera-scrape-info mt-3">
              <dl>
                <div>
                  <dt>取得開始日</dt>
                  <dd v-html='type.startDate'></dd>
                </div>
                <div>
                  <dt>最小粒度</dt>
                  <dd v-html='type.intervalNum + type.intervalUnit'></dd>
                </div>
                <div
                  v-if="type.typeInsights"
                  class="comment">
                  <dt>備考</dt>
                  <dd v-html='type.typeInsights'></dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </section>
</template>

<script>
import MetricsBlurGraph from './MetricsBlurGraph.vue';

export default {
  name: 'MetricsAdditionalContract',
  components: {
    MetricsBlurGraph,
  },
  props: ['projectName', 'metricTypes'],
  methods: {},
  data: () => ({
    isLoading: true,
  }),
  watch: {
    metricTypes() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
  },
};
</script>

<style scoped>

</style>
