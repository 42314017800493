<template>
  <b-container>
    <span class="loading-icon">
    </span>
    <IndexInfo :indexInfo='indexInfo' />
    <Metrics :indexName='indexName' :metrics='metrics' />
  </b-container>
</template>

<script>
import * as Http from '../lib/http/http';
import Index from '../lib/model/index/index';
import Session from '../lib/model/user/session';
import Init from './Init.vue';
import IndexInfo from '../components/IndexInfo.vue';
import Metrics from '../components/IndexMetrics.vue';

export default {
  name: 'IndexDetail',
  mixins: [
    Init,
  ],
  components: {
    IndexInfo,
    Metrics,
  },
  data: () => ({
    sessionToken: {},
    indexName: '',
    indexInfo: new Index(),
    metrics: [],
  }),
  methods: {
    async init() {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return;
      }
      this.sessionToken = token;
      await Promise.all([
        this.fetchIndexInfo(),
        this.fetchMetrics(),
      ]);
    },
    async fetchIndexInfo() {
      this.indexInfo = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_VERSION}/index-metrics/info/${this.indexName}`,
        this.sessionToken,
      )
        .catch((e) => {
          console.error(e);
          return [];
        });
    },
    async fetchMetrics() {
      const metrics = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_VERSION}/index-metrics/translated/${this.indexName}`,
        this.sessionToken,
      ).catch((e) => {
        console.error(e);
        return [];
      });
      if (!metrics) {
        this.removeMetrics({ indexName: this.indexName });
        return;
      }
      this.metrics = metrics;
    },
  },
  async mounted() {
    this.indexName = decodeURIComponent(this.$route.params.index);
    await this.init();
  },
};
</script>
