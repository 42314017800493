import { keyCheck } from '../../helper/object-helper';

import BaseEntity from '../base-entity';

export default class Index extends BaseEntity {
  constructor(obj) {
    super(obj);
    this.indexName = keyCheck(obj, 'indexName') ? obj.indexName : '';
    this.indexDisplayName = keyCheck(obj, 'indexDisplayName') && !!obj.indexDisplayName ? obj.indexDisplayName : this.indexName;
    this.description = keyCheck(obj, 'description') ? obj.description : '';
  }

  static default() {
    return new Index();
  }
}
