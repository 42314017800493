export const sendContactEvent = () => sendEvent({ event: 'Contact' });
export const sendLoginEvent = () => setTimeout(() => sendEvent({ event: 'Login' }), 6000);
export const resetUserId = () => sendEvent({ user_id: '' });
export const setUserId = (userhash) => sendEvent({ user_id: userhash.substring(0, 36) });
export const sendSignUpEvent = () => {
  const cookies = window.document.cookie.split(';').reduce((acc, c) => {
    const kv = c.trim().split('=');
    return {
      ...acc,
      [kv[0]]: kv[1],
    };
  }, {});
  const refCookie = cookies.find((c) => c.ref);
  const ref = refCookie ? refCookie.ref : '';
  window.document.cookie = 'ref=; max-age=0'; // refをクリアする。
  sendEvent({ event: 'SignUp', ref });
};

export const sendVerifyEvent = () => sendEvent({ event: 'Verify' });

export const sendEvent = (events) => {
  window.dataLayer = window.dataLayer || [];
  console.log({ events });
  window.dataLayer.push({ ...events });
};
