import * as moment from 'moment';
import { keyCheck } from '../../helper/object-helper';

export const DisplayType = {
  graph: 'graph',
  table: 'table',
  image: 'image',
};

export const Category = {
  default: 'default',
  cm: 'cm',
};

export const IntervalUnit = {
  '': '',
  day: 'day',
  week: 'week',
  month: 'month',
};

export const RateCheckTarget = {
  '': '',
  mean: 'mean',
  max: 'max',
  min: 'min',
};

export const RateCheckDirection = {
  '': '',
  up: 'up',
  down: 'down',
  both: 'both',
};

export const SeqCheckTarget = {
  '': '',
  zero: 'zero',
  any: 'any',
};

export default class Type {
  constructor(obj) {
    this.typeKey = keyCheck(obj, 'typeKey') ? obj.typeKey : '';
    this.typeDescription = keyCheck(obj, 'typeDescription') && obj.typeDescription !== 'Null' ? obj.typeDescription : '';
    this.typeDescriptionEn = keyCheck(obj, 'typeDescriptionEn') && obj.typeDescriptionEn !== 'Null' ? obj.typeDescriptionEn : '';
    this.typeInsights = keyCheck(obj, 'typeInsights') && obj.typeInsights !== 'Null' ? obj.typeInsights : '';
    this.sourceUrl = keyCheck(obj, 'sourceUrl') && obj.sourceUrl !== 'Null' ? obj.sourceUrl : '';
    this.displayType = keyCheck(obj, 'displayType') && obj.displayType !== 'Null' ? DisplayType[obj.displayType] : 'graph';
    this.available = !(keyCheck(obj, 'available') && obj.available === false);
    this.startDate = keyCheck(obj, 'startDate') && moment(obj.startDate).isValid() ? moment(obj.startDate).format('YYYY/MM/DD') : '';
    this.intervalNum = keyCheck(obj, 'intervalNum') && Number(obj.intervalNum) ? Number(obj.intervalNum) : '';
    this.intervalUnit = keyCheck(obj, 'intervalUnit') ? IntervalUnit[obj.intervalUnit] : '';
    this.category = keyCheck(obj, 'category') ? obj.category : 'default';
    this.newCategory = keyCheck(obj, 'newCategory') ? obj.newCategory : 'default';
  }
}
