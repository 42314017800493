<template>
  <v-container
    grid-list-md
    text-xs-center
  >
    <v-flex xs12 sm6 md3>
    </v-flex>

    <v-layout
      row
      text-xs-left
      wrap
      v-if="!!searchedFlag && projectInfoList.length > 0"
    >
      <v-flex
        v-for="projectInfo in projectInfoList" :key="projectInfo.id"
        xs4
      >
        <SearchedList :projectInfo='projectInfo'/>
      </v-flex>
    </v-layout>

    <v-layout
      v-else
      text-xs-left
    >
      <v-flex>
<!--        <v-card-->
<!--          class="mx-auto"-->
<!--          color="primary"-->
<!--          dark-->
<!--        >-->
        <v-card
          class="mx-auto"
          color="primary"
          light
        >
          <v-container fluid>

            <v-card-text class="headline font-weight-bold">
              <div>
                {{ sorryMessage1 }}
              </div>
              <div>
                {{ sorryMessage2 }}
              </div>
            </v-card-text>

            <v-layout justify-end>
              <a style="cursor: pointer; padding-top: 10px; color: black;" @click="toForm">
                {{ toFormMessage }}
              </a>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import * as Http from '../../lib/http/http';
import SearchedList from '../../components/SearchedList.vue';

export default {
  name: 'SearchResult',
  components: {
    SearchedList,
  },
  props: [],
  data: () => ({
    projectInfoList: [],
    securityCode: '',
    searchedFlag: false,
    sorryMessage1: '申し訳ございません。検索いただいた銘柄コードについての企業データが現在存在しません。',
    sorryMessage2: 'お問い合わせフォームにて、分析したい企業、データについてご依頼いただければ幸いでございます。',
    toFormMessage: 'お問い合わせはこちらのリンクよりお願いいたします。',
  }),
  methods: {
    async init() {
      this.securityCode = this.$route.params.securityCode;
      const fetchedProjectInfoList = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_VERSION}/api/company/${this.securityCode}`,
      ).catch((e) => {
        console.error(e);
        return [];
      });
      this.searchedFlag = true;
      if (!fetchedProjectInfoList || !Array.isArray(fetchedProjectInfoList)) return;
      this.projectInfoList = fetchedProjectInfoList;
    },
    toForm() {
      window.open(process.env.VUE_APP_GOOGLE_FORM_LINK);
    },
  },
  async created() {
    this.searchedFlag = false;
    await this.init();
  },
};
</script>
