<template>
  <div>
    <h2>契約企業一覧</h2>
    <div class="pera-panel">
      <b-row class="m-3 py-3 px-2" style="background-color: rgba(255,255,255,0.5);">
        <b-col>
          <b-form inline v-on:reset="resetProjects">
            <label for="search-box"><strong>絞り込み</strong></label>
            <b-input-group style="margin-left: auto;">
              <b-form-input
                  id="search-box"
                  ref="searchBox"
                  label="Outline"
                  placeholder="銘柄名・銘柄コード"
                  v-on:keyup="searchProjects"
                  v-model="searchText"
                  autofocus
                >
              </b-form-input>
              <input type="text" name="dummy" style="display:none;">
              <b-input-group-append>
                <b-button type="reset" variant="info">クリア</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>
      <b-list-group v-for='project in filteredProjects' :key='project.id'>
        <Project
          v-if="!project.deleteFlag"
          :project='project'
        />
      </b-list-group>
      <b-list-group v-for='packageProject in filteredPackageProjects' :key='packageProject.id'>
        <PackageProject
          v-if="!packageProject.deleteFlag"
          :packageProject='packageProject'
        />
      </b-list-group>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as Http from '../lib/http/http';
import Session from '../lib/model/user/session';
import Project from './Project.vue';
import PackageProject from './PackageProject.vue';
import ProjectModel from '../lib/model/project/project';
import PackageInfo from '../lib/model/package/package-info';

export default {
  name: 'ProjectList',
  mixins: [],
  components: {
    Project,
    PackageProject,
  },
  data: () => ({
    projects: [],
    filteredProjects: [],
    searchJoinedTextList: [],
    packageProjects: [],
    filteredPackageProjects: [],
    searchText: '',
  }),
  methods: {
    ...mapActions({
      getProjectListAllFromLocal: 'ProjectList/getAllFromLocal',
      setProjectList: 'ProjectList/setProjectList',
      resetProjectList: 'ProjectList/resetProjectList',
      removeProjectFromList: 'ProjectList/removeProjectFromList',
      filterProjectMetrics: 'Metrics/filterProjectMetrics',
    }),
    search() {
      this.filteredProjects = this.projects;
      this.filteredPackageProject = this.packageProjects;
      if (!this.searchText || this.searchText === '') return;
      this.filteredProjects = this.filteredProjects
        .filter((_, idx) => !!this.searchJoinedTextList[idx].includes(this.searchText));
      this.filteredPackageProject = this.filteredPackageProject
        .filter((_, idx) => !!this.searchJoinedTextList[idx].includes(this.searchText));
    },
    searchProjects() {
      this.filteredProjects = this.projects;
      this.filteredProjects = this.filteredProjects
        .filter((_, idx) => !!this.searchJoinedTextList[idx].includes(this.searchText.toLowerCase()));
    },
    resetProjects() {
      this.filteredProjects = this.projects;
      this.$refs.searchBox.focus();
    },
    createSearchJoinedTextList() {
      this.searchJoinedTextList = this.projects.map((p) => `${p.securityCode}__${p.companyDisplayName}__${p.companyName}`.toLowerCase());
      this.searchJoinedTextList = [
        ...this.searchJoinedTextList,
        ...this.packageProjects.map((p) => `${p.securityCode}__${p.companyDisplayName}__${p.companyName}`.toLowerCase()),
      ];
    },
    async fetchProjectList(token) {
      const fetchedProjectList = await Http.get(`https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_VERSION}/list`, token);
      if (!fetchedProjectList || !Array.isArray(fetchedProjectList)) {
        this.resetProjectList();
        return [];
      }
      return fetchedProjectList;
    },
    fetchPackageProjectList(token) {
      return Http.get(`https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_VERSION}/package-project-metrics/info/list`, token)
        .catch(() => []);
    },
  },
  async created() {
    const projects = await this.getProjectListAllFromLocal();
    this.projects = projects;
    this.filteredProjects = projects;
    this.packageProjects = [];
    this.filteredPackageProjects = [];
    this.createSearchJoinedTextList();
  },
  async mounted() {
    const token = await Session.getSession();
    if (!token) {
      console.error('Session Error.');
      return;
    }
    const fetchedProjectList = await this.fetchProjectList(token);
    const fetchedPackageProjectList = await this.fetchPackageProjectList(token);
    const projectList = fetchedProjectList.map((p) => new ProjectModel(p));
    const projectCompanyNameList = projectList.map((p) => p.companyName);
    const packageInfoList = fetchedPackageProjectList
      .map((p) => new PackageInfo(p))
      .filter((p) => !projectCompanyNameList.includes(p.companyName));

    this.projects = projectList;
    this.setProjectList(projectList);
    this.filteredProjects = projectList;
    this.packageProjects = packageInfoList;
    this.filteredPackageProjects = packageInfoList;
    console.log('this.filteredPackageProject', this.filteredPackageProject);
    this.createSearchJoinedTextList();
    this.filterProjectMetrics(projectList.map((p) => p.companyName));
  },
};
</script>

<style lang="scss" scoped>
  /*@import '../assets/scss/custom';*/
  /*@import '~bootstrap/scss/bootstrap.scss';*/
  .alpha { background-color: purple; }
  .form-control:focus {
    box-shadow:none;
  }
  .btn-info:focus, .btn-info:hover {
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .pera-panel {
    //background-color: #303030;
    padding: 1rem 2rem;
  }
</style>
