import { keyCheck } from '../../helper/object-helper';

import BaseEntity from '../base-entity';
import Index from './index';

export default class IndexGroup extends BaseEntity {
  constructor(obj) {
    super(obj);
    this.indexGroupName = keyCheck(obj, 'indexGroupName') ? obj.indexGroupName : '';
    this.indexGroupDisplayName = keyCheck(obj, 'indexGroupDisplayName') && !!obj.indexGroupDisplayName ? obj.indexGroupDisplayName : this.indexGroupName;
    this.indexInfoList = keyCheck(obj, 'indexInfoList') && Array.isArray(obj.indexInfoList) ? obj.indexInfoList.map((i) => new Index(i)) : [];
  }

  static default() {
    return new IndexGroup();
  }
}
