<template>
  <b-container class="front">
    <b-row align-v="center">
      <b-col cols="12" lg="6" order-lg="0">
        <h2>オルタナティブデータで、<br class="lg-visible" />他社を置き去りにする<br class="lg-visible" />投資戦略を。</h2>
        <p>PERAGARUは、ターゲット企業のオルタナティブデータを取得・分析することで、貴社の投資ニーズに合わせた情報を提供します。<br>
        即時性が高く、市場で共有されていない非公開情報を活用することで、貴社の投資戦略をさらにユニークなものに。<br>
        エクスクルーシブプランをご利用いただくことで、企業情報を貴社独占とすることも可能です。</p>
      </b-col>
      <b-col cols="12" lg="6" order-lg="1">
        <img class="img-fluid" src="/front-img1.png">
      </b-col>
      <b-col cols="12" lg="6" order-lg="2" class="center-col">
        <b-button class="execute-btn contact-btn mt-3"
          @click="toContactWithWhitepaper">サービス資料を無料配布中</b-button>
      </b-col>
    </b-row>
    <b-row class="mt-5 gray" align-v="center">
      <b-col cols="12" order-lg="0">
        <h2 class="text-center my-5">PERAGARUでできること</h2>
      </b-col>
      <b-col cols="12" lg="6" order-lg="1" class="center-col">
        <img class="img-fluid" src="/front-img2.png">
      </b-col>
      <b-col cols="12" lg="6" order-lg="1">
        <h3>日本で上場する企業の<br class="lg-visible" />オルタナティブデータを閲覧</h3>
        <p>PERAGARU独自に収集・算出したオルタナティブデータを活用し、企業の業績予測やモメンタムを予想することができます。</p>
      </b-col>
    </b-row>
    <b-row class="mt-5" align-v="center">
      <b-col cols="12" order-lg="0">
        <h2 class="text-center my-5">オルタナティブデータとは…</h2>
      </b-col>
      <b-col cols="12" lg="6" order-lg="2" class="center-col">
        <img class="img-fluid lp-png" src="/front-img3.png">
      </b-col>
      <b-col cols="12" lg="6" order-lg="1">
        <h3>新たなリターンの源泉を<br class="lg-visible" />生み出すデータ</h3>
        <p>オルタナティブデータとは、Webスクレイピングやスマートフォン位置情報、SNS（交流サイト）の投稿などを分析し、投資家に新たな優位性を生み出すデータです</p>
      </b-col>
    </b-row>
    <b-row class="mt-5 gray" align-v="center">
      <b-col cols="12" order-lg="0">
        <div style="display:flex; justify-content:center;">
          <h2 class="my-5">データパッケージ</h2>
        </div>
      </b-col>
      <b-col cols="12" lg="6" order-lg="1" class="center-col">
        <img class="img-fluid" style="width: 1000px" src="/front-img4.png">
      </b-col>
      <b-col cols="12" lg="6" order-lg="2">
        <h3>複数銘柄を比較可能なデータ群</h3>
        <p class="mb-5">シネコンの予約数、SaaS銘柄の資料請求数、太陽光発電所の日照時間、テレビCMの放映時間、自動車販売店舗の人流などのデータを閲覧可能です。</p>
        <div style="display:flex;flex-wrap:wrap">
          <div v-for='pack in packageList' :key='pack.name' class="col-4 mb-3 pl-0">
            <button class="btn btn-info w-100"
              @click="goto(pack.path)"
              :disabled="pack.disabled">
              {{ pack.name }}
            </button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12">
        <h2 class="text-center my-5">掲載銘柄紹介</h2>
      </b-col>
      <b-col cols="6" md="4" class="p-2"
        v-for='company in companyList' :key='company.securityCode'>
<!--        <div class="pera-card h-100">-->
<!--          <b>{{ company.companyDisplayName }}</b> ({{ company.securityCode }})-->
<!--          <ul class="pl-3 m-0">-->
<!--            <li v-for='type in company.types' :key='type'>-->
<!--              <small>{{ type.description }} <span v-if="type.startDate">({{ type.startDate }}~)</span></small>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
        <div class="pera-card">
          <b>{{ company.companyDisplayName }}</b> ({{ company.securityCode }})
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-5 justify-content-md-center">
      <b-col cols="12" class="text-center mt-5">
        <b-button class="execute-btn extra-large-btn"
          @click="toContact">
          契約の申し込み・料金等のお問い合わせはこちらから
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';
import Init from './Init.vue';
import router from '../router';
import { keyCheck } from '../lib/helper/object-helper';

export default {
  components: {},
  name: 'About',
  mixins: [
    Init,
  ],
  data: () => ({
    companyList: [],
    packageList: [],
  }),
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    toContact() {
      router.push({ path: '/contact' });
    },
    toContactWithWhitepaper() {
      router.push({ name: 'contactForm', params: { whitepaper: '1' } });
    },
    goto(link) {
      router.push({ path: link });
    },
    async init() {
      this.packageList = [
        {
          name: 'TVメタデータ',
          path: '/tvMeta',
          disabled: false,
        },
        {
          name: 'シネコン指数',
          path: '/cinecon',
          disabled: false,
        },
        {
          name: 'Saas指数',
          path: '/',
          disabled: true,
        },
        {
          name: '中古車販売銘柄位置情報',
          path: '/',
          disabled: true,
        },
        {
          name: '太陽光発電所日照時間',
          path: '/',
          disabled: true,
        },
      ];
      const lpInfoList = await axios.get(`https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_VERSION}/list/lpInfo`);
      this.companyList = lpInfoList.data.flatMap((lp) => {
        if (keyCheck(lp, 'types') && lp.types.length > 3) {
          lp.types.splice(3);
          lp.types.push({
            description: '・・・',
            startDate: '',
          });
        }
        return lp;
      });
      const url = new URL(window.location.href);
      const ref = url.searchParams.get('ref');
      if (ref) document.cookie = `ref=${ref}`;
    },
  },
};
</script>
