<template>
  <b-container class="front for-personal">
    <b-row align-v="start">
      <b-col cols="12" order-lg="0">
        <h1 class="font-weight-bold">シネコン指数</h1>
      </b-col>
      <b-col cols="12" order-lg="0" class="my-3">
        チケット販売指数(複数シネコンのチケット販売数を指数化したもの)をご覧いただけます。
        各映画タイトル別にも当該指数を閲覧可能です。
      </b-col>
    </b-row>
    <b-row align-v="start">
      <b-col cols="12" order-lg="0" class="mt-2">
        <h4 class="mb-3 font-weight-bold">データ提供中映画タイトル例</h4>
        <div class="pera-card">
          <li>余命１０年</li>
          <!-- eslint-disable-next-line no-irregular-whitespace -->
          <li>映画ドラえもん　のび太の宇宙小戦争　２０２１</li>
          <!-- eslint-disable-next-line no-irregular-whitespace -->
          <li>ＳＩＮＧ　シング　ネクストステージ</li>
          <li>名探偵コナン ハロウィンの花嫁</li>
          <li>ファンタスティック・ビーストとダンブルドアの秘密</li>
        </div>
        <small>※前月、および前々月の月間ランキング上位３作品のデータを提供していますが、ご要望があれば他の作品データの閲覧も可能です。</small>
        <!-- <ul>
          <li>劇場版 呪術廻戦0</li>
          <li>コンフィデンスマンJP 英雄編</li>
          <li>99.9-刑事専門弁護士-THE MOVIE</li>
          <li>スパイダーマン ノー・ウェイ・ホーム</li>
        </ul> -->
        <!-- <div class="">※前月、および前々月の月間ランキング上位３作品のデータを提供していますが、ご要望があれば他の作品データの閲覧も可能です。</div> -->
      </b-col>
      <b-col cols="12" order-lg="0" class="my-3">
        <img src="/cinecon2.png" alt="no img" class="w-100 py-0">
      </b-col>
      <b-col cols="12" order-lg="0" class="mt-2">
        <h4 class="mb-3 font-weight-bold">指数活用例</h4>
        <div class="pera-card">
          <div class="mb-2 font-weight-bold">シネコン銘柄の売買判断</div>
          <div>
            シネコンのチケット販売指数と個別シネコン銘柄の業績数値の相関を取得します。
            その上で、チケット販売指数を用いて財務モデルを作成し、次回決算で発表される業績数値を予測します。
            そうすれば、当該個別銘柄の決算発表前にポジションを取り、アルファを獲得する可能性が高まります。
          </div>
        <!-- </div>
        <div class="pera-card"> -->
          <div class="mt-3 mb-2 font-weight-bold">ヒット作品関連銘柄の発掘</div>
          <div>
            各映画タイトルのチケット販売指数を比較することで、伸びている映画タイトルをタイムラグ少なく把
            握することができます。これにより、グッズメーカーなどの当該タイトルに関わった会社のカタリスト
            を抽出するアイデアを他の投資家に先駆けて得ることができます。
          </div>
        </div>
      </b-col>
      <b-col cols="12" order-lg="0" class="mt-5">
        <h4 class="mb-3 font-weight-bold">基本情報</h4>
        <div class="pera-card">
          <div class="font-weight-bold">データ元</div>
          <div>シネコン企業とのデータ連携&amp;webスクレイピング</div>
          <div class="mt-3 font-weight-bold">データ利用料金</div>
          <div>100,000円 <span class="small">(税抜)</span></div>
          <div class="mt-3 font-weight-bold">データの粒度</div>
          <div>日次</div>
          <div class="mt-3 font-weight-bold">データの更新頻度</div>
          <div>毎月上旬に前月分データを更新</div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Init from '../Init.vue';
// import router from '../router';

export default {
  components: {},
  mixins: [
    Init,
  ],
  name: 'Cinecon',
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>
