import BaseEntity from '../base-entity';

import { keyCheck } from '../../helper/object-helper';

export default class ShareHoldingReport extends BaseEntity {
  constructor(obj) {
    super(obj);
    this.securityCode = keyCheck(obj, 'securityCode') ? obj.securityCode : '';
    this.name = keyCheck(obj, 'name') ? obj.name : '';
    this.title = keyCheck(obj, 'title') ? obj.title : '';
    this.shareholder = keyCheck(obj, 'shareholder') ? obj.shareholder : '';
    this.holdingRatio = keyCheck(obj, 'holdingRatio') ? obj.holdingRatio : 0;
  }
}
