<template>
  <b-container>
    <div class="panel-form">
      <div class="panel-form-content">
        <div class="text-center">
          <h5 class="mb-4">{{ titleForStatus }}</h5>
        </div>
        <b-form>
          <b-form-group>
            <label for="username" class="sr-only">お名前またはメールアドレス</label>
            <b-form-input
              type="text"
              v-model="username"
              id="username"
              :readonly="status === 'newPasswordRequired' || status === 'passwordResetRequired'"
              class="form-control-lg"
              placeholder="お名前またはメールアドレス"
              :state="usernameValidation" required></b-form-input>
            <b-form-invalid-feedback :state="usernameValidation">※必須です</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <label for="password" class="sr-only">パスワード</label>
            <b-form-input
              type="password"
              v-model="password"
              id="password"
              :readonly="status === 'newPasswordRequired' || status === 'passwordResetRequired'"
              class="form-control-lg"
              placeholder="パスワード"
              :state='passwordValidation' required></b-form-input>
            <b-form-invalid-feedback :state="passwordValidation">※必須です</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="status === 'newPasswordRequired' || status === 'passwordResetRequired'">
            <label for="newPassword" class="sr-only">新しいパスワード</label>
            <b-form-input
              type="password"
              v-model="newPassword"
              id="newPassword"
              class="form-control-lg"
              placeholder="新しいパスワード"
              :state='newPasswordValidation' required></b-form-input>
            <b-form-invalid-feedback :state="newPasswordValidation">※必須です</b-form-invalid-feedback>
            <b-form-invalid-feedback :state="checkNewPasswordCondition">※パスワードは英数字大文字小文字記号を含めて10文字以上としてください</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="status === 'newPasswordRequired' || status === 'passwordResetRequired'">
            <label for="password" class="sr-only">新しいパスワード（再入力）</label>
            <b-form-input
              type="password"
              v-model="retypedNewPassword"
              id="password"
              class="form-control-lg"
              placeholder="新しいパスワード（再入力）"
              :state='retypedNewPasswordValidation' required></b-form-input>
            <b-form-invalid-feedback :state="retypedNewPasswordValidation">※必須です</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="status === 'passwordResetRequired'">
            <label for="password" class="sr-only">認証コード</label>
            <b-form-input
              type="text"
              v-model="verificationCode"
              id="verificationCode"
              class="form-control-lg"
              placeholder="認証コード"
              :state='verificationCodeValidation' required></b-form-input>
            <b-form-invalid-feedback :state="verificationCodeValidation">※必須です</b-form-invalid-feedback>
          </b-form-group>
          <div>
            <b-button
              v-on:click="doSubmit"
              v-if="status === 'Signin'"
              class="btn btn-primary btn-block execute-btn">ログインする</b-button>
            <b-button
              v-on:click="setNewPassword"
              v-else-if="status === 'newPasswordRequired'"
              class="btn btn-primary btn-block execute-btn">新しいパスワードに変更</b-button>
            <b-button
              v-on:click="resetPassword"
              v-else-if="status === 'passwordResetRequired'"
              class="btn btn-primary btn-block execute-btn">パスワードをリセットする</b-button>
          </div>
        </b-form>
      </div>
      <p class="text-center mt-4">アカウントをお持ちでない方は
        <b-link to="signup">新規登録</b-link>
      </p>
      <p class="text-center mt-4">パスワードをお忘れの方は
        <b-link to="forget/password">パスワード変更</b-link>
        {{ checkNewPasswordCondition }}
      </p>
    </div>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import router from '../router';
import Signin from '../lib/model/user/signin';
import * as Analytics from '../lib/infrastructure/google/analytics';
// import * as Crypto from '../lib/helper/crypto-helper';

export default {
  name: 'UserSign',
  props: [
    'type',
  ],
  data: () => ({
    username: '',
    password: '',
    newPassword: '',
    retypedNewPassword: '',
    invalidNewPasswordMessage: '',
    loginErrorMessage: '',
    verificationCode: '',
    status: '',
    titleForStatus: '',
    signInStatus: {},
    isSubmitted: false,
    isPasswordResetSubmitted: false,
    passwordConditionRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{}|;':",.<>/?\\])(?=.*[a-zA-Z\d!@#$%^&*()_+\-=[\]{}|;':",.<>/?\\]).{10,}$/,
  }),
  methods: {
    ...mapActions({
      getFromLocal: 'Login/getFromLocal',
      setLoginUser: 'Login/setLoginUser',
      resetProjectList: 'Login/resetProjectList',
    }),
    async doSubmit() {
      this.isSubmitted = true;
      console.log(this.type);
      switch (this.status) {
        case 'Signin':
          if (this.usernameValidation
          && this.passwordValidation) {
            await this.doSignin();
          }
          break;
        case 'newPasswordRequired':
          if (this.newPasswordValidation
          && this.retypedNewPasswordValidation) {
            await this.setNewPassword();
          }
          break;
        case 'passwordResetRequired':
          if (this.newPasswordValidation
          && this.retypedNewPasswordValidation
          && this.verificationCodeValidation) {
            await this.resetPassword();
          }
          break;
        default:
          break;
      }
    },
    showMsgModal(title, msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: `${title}`,
        size: 'mid',
        buttonSize: 'mid',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    async doSignin() {
      const signInStatus = await Signin.doSignin({
        username: this.username,
        password: this.password,
      })
        .catch((err) => {
          // ログイン失敗時にここを通ってない？
          console.error(err);
          console.error('Signin failed.');
          return '';
        });
      this.loginErrorMessage = '';
      if (signInStatus.success()) {
        await this.setLoginUser(signInStatus.username);
        const encryptedUserName = await this.getFromLocal()
          .catch((e) => {
            console.error(e);
            return '';
          });
        Analytics.sendLoginEvent(encryptedUserName);
        router.push({ path: 'list' });
      }
      if (signInStatus.failed()) {
        this.loginErrorMessage = '名前かパスワードが間違っています';
        this.showMsgModal('ログインエラー', this.loginErrorMessage);
      }
      if (signInStatus.newPasswordRequired()) {
        this.status = signInStatus.getStatus();
        this.signInStatus = signInStatus;
      }
      if (signInStatus.passwordResetRequired()) {
        this.status = signInStatus.getStatus();
        this.signInStatus = signInStatus;
      }
    },
    async setNewPassword() {
      if (this.newPassword !== this.retypedNewPassword) {
        this.invalidNewPasswordMessage = '新しいパスワードが正しくありません';
        this.showMsgModal('確認してください', this.invalidNewPasswordMessage);
        return;
      }
      if (!this.passwordConditionRegex.test(this.password)) {
        this.invalidNewPasswordMessage = '※パスワードは英数字大文字小文字記号を含めて10文字以上としてください。記号は^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ ` + =を使用できます。';
        this.showMsgModal('確認してください', this.invalidNewPasswordMessage);
        return;
      }
      this.isPasswordResetSubmitted = true;
      this.invalidNewPasswordMessage = '';
      const setStatus = await this.signInStatus.setNewPassword(this.newPassword)
        .catch((err) => {
          console.error(err);
          this.showMsgModal('Error', '失敗しました');
          return '';
        });
      if (!setStatus) return;
      if (setStatus.success()) router.push({ path: 'list' });
    },
    async resetPassword() {
      if (this.newPassword !== this.retypedNewPassword) {
        this.invalidNewPasswordMessage = '新しいパスワードが正しくありません';
        this.showMsgModal('確認してください', this.invalidNewPasswordMessage);
        return;
      }
      this.invalidNewPasswordMessage = '';
      const status = await this.signInStatus.setResetPassword(
        this.newPassword,
        this.verificationCode,
      )
        .catch((err) => {
          console.error(err);
          this.showMsgModal('Error', '失敗しました');
          return '';
        });
      if (!status) return;
      if (status.success()) {
        this.password = this.newPassword;
        this.doSignin();
      }
    },
  },
  created() {
    this.status = this.type;
    // this.status = 'newPasswordRequired'; // test
    // this.status = 'passwordResetRequired'; // test
    this.titleForStatus = (this.status === 'Signin') ? 'ログイン' : this.titleForStatus;
    this.titleForStatus = (this.status === 'newPasswordRequired') ? 'パスワード再設定' : this.titleForStatus;
    this.titleForStatus = (this.status === 'passwordResetRequired') ? 'パスワードリセット' : this.titleForStatus;
  },
  mounted() {
  },
  computed: {
    usernameValidation() {
      return this.isSubmitted ? this.username !== '' : null;
    },
    passwordValidation() {
      return this.isSubmitted ? this.password !== '' : null;
    },
    newPasswordValidation() {
      return this.isSubmitted ? this.newPassword !== '' : null;
    },
    retypedNewPasswordValidation() {
      return this.isSubmitted ? this.retypedNewPassword !== '' : null;
    },
    verificationCodeValidation() {
      return this.isSubmitted ? this.verificationCode !== '' : null;
    },
    checkNewPasswordCondition() {
      // 10文字以上、英字小文字大文字数字特殊文字をそれぞれ1つ以上含む正規表現
      console.log('regex.test(this.password)', this.passwordConditionRegex.test(this.password), this.isPasswordResetSubmitted);
      return this.isPasswordResetSubmitted ? !this.passwordConditionRegex.test(this.password) : null;
    },
  },
};
</script>

<style scoped>

</style>
