<template>
  <div>
    <h2>{{ projectInfo.companyDisplayName }} レポート</h2>
    <div class="pera-panel brand-panel">
      <b-row class="align-items-center pera-brand">
        <b-col cols="12" sm="12" md="2" xl="2" class="brand-logo">
          <img class="img-fluid" :src=displayImage>
        </b-col>
        <b-col cols="12" sm="12" md="3" xl="3" class="brand-name">
          <label>銘柄名</label>
          <h4>{{ projectInfo.companyDisplayName }}</h4>
        </b-col>
        <b-col cols="6" sm="6" md="2" xl="2" class="brand-code">
          <label>銘柄コード</label>
          <p>{{ projectInfo.securityCode }}</p>
        </b-col>
        <b-col cols="6" sm="6" md="2" xl="1" class="brand-code">
          <label>決算月</label>
          <p>{{ fiscalEndMonth }}</p>
        </b-col>
        <b-col cols="6" sm="6" md="3" xl="4" class="brand-url">
          <label>URL</label>
          <button @click="gotoCompanyProfile"
              type="button"
              class="btn btn-link">
            {{ companyUrl }}
          </button>
        </b-col>
      </b-row>
    </div>
    <b-row class="mt-4">
      <div class="col-12 col-md-6">
        <DisclosureList :securityCode="projectInfo.securityCode" />
      </div>
      <div class="col-12 col-md-6">
        <ShareHoldingReportList :securityCode="projectInfo.securityCode" />
      </div>
    </b-row>
  </div>
</template>

<script>
import * as moment from 'moment';

import Session from '../lib/model/user/session';
import StockPrice from '../lib/model/stock/stock-price';

import DisclosureList from './stock/DisclosureList.vue';
import ShareHoldingReportList from './stock/ShareHoldingReportList.vue';

export default {
  name: 'ProjectInfo',
  props: [
    'projectInfo',
  ],
  components: {
    DisclosureList,
    ShareHoldingReportList,
  },
  data: () => ({
    stockPrice: new StockPrice(),
    publicFlag: true,
  }),
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      if (this.$route.path.includes('/public/company/detail/')) return;
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return;
      }
      this.sessionToken = token;
      this.publicFlag = false;
    },
    gotoCompanyProfile() {
      if (!!this.projectInfo.companyUrl && this.projectInfo.companyUrl !== 'Null') {
        window.open(this.projectInfo.companyUrl);
      }
    },
    formatDate(time) {
      return moment(time).format('YYYY年MM月DD日');
    },
  },
  computed: {
    displayImage() {
      return this.projectInfo.companyImageUrl && this.projectInfo.companyImageUrl !== 'Null' ? this.projectInfo.companyImageUrl : '/in-preparation-img.png';
    },
    companyUrl() {
      return !!this.projectInfo.companyUrl && this.projectInfo.companyUrl !== 'Null' ? this.projectInfo.companyUrl : '-';
    },
    fiscalEndMonth() {
      return (this.projectInfo.fiscalEndMonth && this.projectInfo.fiscalEndMonth.replace(/^0/, '')) || '-';
    },
    updatedDate() {
      if (this.publicFlag
        || !this.stockPrice
        || !this.stockPrice.date
        || this.stockPrice.date === '0'
        || this.stockPrice.date === 0
        || this.stockPrice.closingPrice === 0
      ) return '-年-月-日';
      return `${this.formatDate(this.stockPrice.date)}`;
    },
  },
  watch: {
    async projectInfo() {
      await this.init();
    },
  },
};
</script>

<style scoped>

</style>
