import * as Cognito from '../../infrastructure/aws/cognito/cognito';

const SignInStatus = {
  onSuccess: 'onSuccess',
  onFailure: 'onFailure',
  inputVerificationCode: 'inputVerificationCode',
  newPasswordRequired: 'newPasswordRequired',
  mfaRequired: 'mfaRequired',
  mfaSetup: 'mfaSetup',
  associateSecretCode: 'associateSecretCode',
  selectMFAType: 'selectMFAType',
  totpRequired: 'totpRequired',
  passwordResetRequired: 'passwordResetRequired',
};

export default class Signin {
  constructor({
    status,
    requiredAttributes,
    userAttributes,
    err,
    username,
  }) {
    this.status = status;
    this.errMessage = err;
    this.requiredAttributes = requiredAttributes;
    this.userAttributes = userAttributes;
    this.username = username;
  }

  static async doSignin({ username, password }) {
    const response = await Cognito.login({ username, password });
    console.log(response);
    return new Signin({ ...response, username });
  }

  static checkValid() {
    return Cognito.checkCurrentUserValid();
  }

  success() { return this.status === SignInStatus.onSuccess; }

  getStatus() { return this.status; }

  failed() { return this.status === SignInStatus.onFailure; }

  inputVerificationCode() { return this.status === SignInStatus.inputVerificationCode; }

  newPasswordRequired() { return this.status === SignInStatus.newPasswordRequired; }

  mfaRequired() { return this.status === SignInStatus.mfaRequired; }

  mfaSetup() { return this.status === SignInStatus.mfaSetup; }

  associateSecretCode() { return this.status === SignInStatus.associateSecretCode; }

  selectMFAType() { return this.status === SignInStatus.selectMFAType; }

  totpRequired() { return this.status === SignInStatus.totpRequired; }

  passwordResetRequired() { return this.status === SignInStatus.passwordResetRequired; }

  async setNewPassword(newPassword) {
    if (!this.userAttributes) {
      console.error('UserAttributes is not set.');
      return Promise.reject();
    }
    const response = await Cognito.setNewPassword(
      newPassword,
      this.userAttributes,
    );
    return new Signin(response);
  }

  static async forgetPasssword(username) {
    const response = await Cognito.forgetPassword(username);
    return new Signin(response);
  }

  async setResetPassword(newPassword, verificationCode) {
    const response = await Cognito.confirmPassword(newPassword, verificationCode);
    return new Signin(response);
  }

  static async changePassword(currentPassword, newPassword) {
    const response = await Cognito.changePassword(currentPassword, newPassword);
    return new Signin(response);
  }

  static doSignout() {
    Cognito.logout();
  }
}
