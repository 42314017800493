<template>
  <b-container>
    <div class="panel-form wide">
      <div class="panel-form-content">
        <template
          v-if="phase==='INPUT'">
        <div class="text-center">
          <h5 class="mb-4">パスワードリセット</h5>
        </div>
        <b-form>
          <b-form-group>
            <b-form-input
              type="text"
              v-model="username"
              id="username"
              class="form-control-lg"
              placeholder="お名前またはメールアドレス"
              :state="usernameValidation"></b-form-input>
            <b-form-invalid-feedback :state="usernameValidation">{{ usernameValidationMessage }}</b-form-invalid-feedback>
          </b-form-group>
          <div>
            <b-button
              v-on:click="next"
              class="btn btn-primary btn-block execute-btn">次へ</b-button>
          </div>
        </b-form>
        </template>
        <template
          v-else>
        <div class="text-center">
          <h5 class="mb-4">認証コードと新パスワードの入力</h5>
        </div>
          <b-form-group>
            <b-form-input
              type="text"
              v-model="verificationCode"
              id="verificationCode"
              class="form-control-lg"
              placeholder="認証コード"
              :state='verificationCodeValidation'></b-form-input>
            <b-form-invalid-feedback :state="verificationCodeValidation">{{ verificationCodeValidationMessage }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            description="大文字・小文字・数字・特殊記号を含む10文字以上">
            <b-form-input
              type="password"
              v-model="password"
              id="password"
              class="form-control-lg"
              placeholder="新しいパスワード"
              :state='passwordValidation'></b-form-input>
            <b-form-invalid-feedback :state="passwordValidation">{{ passwordValidationMessage }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <b-form-input
              type="password"
              v-model="confirmedPassword"
              id="confirmedPassword"
              class="form-control-lg"
              placeholder="新しいパスワード（再入力）"
              @focusout="checkConfirmedPassword"></b-form-input>
          </b-form-group>
          <b-row>
            <b-col>
              <b-button
              v-on:click="back"
              :disabled="isSending"
              class="btn btn-secondary back-btn w-100">戻る</b-button>
            </b-col>
            <b-col>
              <b-button
                v-on:click="doReset"
                :disabled="isSending"
                class="btn btn-primary execute-btn w-100">新パスワードを登録</b-button>
            </b-col>
          </b-row>
        </template>
      </div>
    </div>
  </b-container>
</template>

<script>

import router from '../router';
import Signin from '../lib/model/user/signin';
import Init from './Init.vue';

export default {
  name: 'ForgetPassword',
  mixins: [
    Init,
  ],
  components: {
  },
  data: () => ({
    phase: 'INPUT',
    // phase: 'RESET', // test
    isSending: false,
    inquiryFormLink: process.env.VUE_APP_GOOGLE_FORM_LINK,
    username: '',
    usernameValidationMessage: '',
    password: '',
    passwordValidationMessage: '',
    confirmedPassword: '',
    verificationCode: '',
    verificationCodeValidationMessage: '',
    status: '',
    signInStatus: {},
  }),
  created() {},
  mounted() {},
  methods: {
    showMsgModal(title, msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: `${title}`,
        size: 'mid',
        buttonSize: 'mid',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    showCompleteModal(title, msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: `${title}`,
        size: 'mid',
        buttonSize: 'mid',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      }).then(() => {
        // 閉じたらログイン
        this.doSignin();
      });
    },
    async next() {
      if (!this.usernameValidation) {
        return;
      }
      this.isSending = true;
      const setStatus = await Signin.forgetPasssword(this.username);
      if (!setStatus) {
        // ユーザが存在しない場合など
        this.showMsgModal('エラー', 'お名前またはメールアドレスをご確認ください。');
        this.isSending = false;
        return;
      }
      if (!setStatus.success()) {
        // setStatusが帰ってきてるが、異常なステータスの場合
        this.errorMessage = setStatus.errMessage;
        this.showMsgModal('エラー', 'エラーが発生しました。\nお手数ですがお問い合わせよりご連絡ください。');
        this.isSending = false;
        return;
      }
      this.signInStatus = setStatus;
      this.isSending = false;
      this.phase = 'RESET';
    },
    async doReset() {
      this.isSending = true;

      if (!this.verificationCodeValidation || !this.passwordValidation) {
        this.isSending = false;
        return;
      }

      const setStatus = await this.signInStatus
        .setResetPassword(this.password, this.verificationCode);

      if (!setStatus) {
        // ユーザが存在しない場合など（通常ありえない）
        this.isSending = false;
        this.showMsgModal('エラー', 'エラーが発生しました。\nお手数ですがお問い合わせよりご連絡ください。');
        return;
      }
      if (!setStatus.success()) {
        // setStatusが帰ってきてるが、異常なステータスの場合
        this.isSending = false;
        this.showMsgModal('エラー', 'エラーが発生しました。\nお手数ですがお問い合わせよりご連絡ください。');
        return;
      }
      this.isSending = false;
      this.showCompleteModal('パスワード変更完了', 'パスワードの変更が完了しました。\n「OK」を押すとログインします。');
    },
    async doSignin() {
      let invalidFlag = false;
      invalidFlag = !this.username;
      invalidFlag = invalidFlag || !this.password;
      console.log('invalidFlag', invalidFlag);
      if (invalidFlag) return;

      const signInStatus = await Signin.doSignin({
        username: this.username,
        password: this.password,
      });
      console.log('signInStatus', signInStatus);
      if (!signInStatus) {
        this.isKnownError = true;
        this.errorMessage = signInStatus.errMessage;
        return;
      }
      if (signInStatus.success()) router.push({ path: '/list' });
    },
    back() {
      this.phase = 'INPUT';
      this.isKnownError = false;
    },
    checkConfirmedPassword() {
      if (this.confirmedPassword !== this.password) this.showMsgModal('確認してください', 'パスワードが再入力されたパスワードと一致していません');
    },
    toForm() {
      window.open(process.env.VUE_APP_GOOGLE_FORM_LINK);
    },
  },
  computed: {
    usernameValidation() {
      return this.username !== '';
    },
    verificationCodeValidation() {
      return this.verificationCode !== '';
    },
    passwordValidation() {
      // ref) https://qiita.com/mpyw/items/886218e7b418dfed254b#%E5%8D%8A%E8%A7%92%E8%8B%B1%E6%95%B0%E5%AD%97%E8%A8%98%E5%8F%B7%E3%82%92%E3%81%9D%E3%82%8C%E3%81%9E%E3%82%8C1%E7%A8%AE%E9%A1%9E%E4%BB%A5%E4%B8%8A%E5%90%AB%E3%82%808%E6%96%87%E5%AD%97%E4%BB%A5%E4%B8%8A100%E6%96%87%E5%AD%97%E4%BB%A5%E4%B8%8B%E3%81%AE%E6%AD%A3%E8%A6%8F%E8%A1%A8%E7%8F%BE
      // 1. (「最初に記号 or A-Z」が0桁以上で[a-z]を含む)
      // 2. (「最初に記号 o ra-z」が0桁以上で[A-Z]を含む)
      // 3. (「最初に記号」が0桁以上で数字を含む)
      // 4. (「a-zA-Z数字」が0桁以上記号を含む)
      // 以上の条件で10桁以上
      return this.password !== '' && !!this.password.match(/^(?=[!-@[-`{-~A-Z]{0,}[a-z])(?=[!-@[-`{-~a-z]{0,}[A-Z])(?=[!-/:-~]{0,}\d)(?=[a-zA-Z\d]{0,}[!-/:-@[-`{-~])[!-~]{10,}$/);
    },
  },
  watch: {
    username() {
      if (this.username === '') {
        this.usernameValidationMessage = '※必須です';
      }
    },
    verificationCode() {
      if (this.verificationCode === '') {
        this.verificationCodeValidationMessage = '※必須です';
      }
    },
    password() {
      if (this.password === '') {
        this.passwordValidationMessage = '※必須です';
      }
      if (this.password !== '' && !this.password.match(/^(?=[!-@[-`{-~A-Z]{0,}[a-z])(?=[!-@[-`{-~a-z]{0,}[A-Z])(?=[!-/:-~]{0,}\d)(?=[a-zA-Z\d]{0,}[!-/:-@[-`{-~])[!-~]{10,}$/)) {
        this.passwordValidationMessage = 'パスワードの形式に誤りがあります';
      }
    },
  },
};
</script>

<style scoped>

</style>
