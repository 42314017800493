<template>
  <div>
    <h2>{{ indexInfo.indexDisplayName }} レポート</h2>
    <div class="pera-panel brand-panel">
      <b-row class="align-items-center pera-brand">
        <b-col cols="12" md="3" class="brand-name">
          <label>指数名</label>
          <h4>{{ indexInfo.indexDisplayName }}</h4>
        </b-col>
        <b-col cols="8" md="0" class="brand-url">
          <label>説明</label>
          <p>{{ indexInfo.description }}</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';

import Session from '../lib/model/user/session';

export default {
  name: 'IndexInfo',
  props: [
    'indexInfo',
  ],
  components: {},
  data: () => ({}),
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      if (this.$route.path.includes('/public/company/detail/')) return;
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return;
      }
      this.sessionToken = token;
    },
    formatDate(time) {
      return moment(time).format('YYYY年MM月DD日');
    },
  },
  computed: {
  },
  watch: {
    async indexInfo() {
      await this.init();
    },
  },
};
</script>

<style scoped>

</style>
