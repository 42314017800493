<template>
  <b-container>
    <div class="panel-form wide">
      <div class="panel-form-content">
        <template
          v-if="phase==='INPUT'">
        <div class="text-center">
          <h5 class="mb-4">新規会員登録</h5>
        </div>
        <b-form>
          <b-form-group
            description="半角英数字のみ">
            <b-form-input
              type="text"
              v-model="username"
              id="username"
              class="form-control-lg"
              placeholder="お名前"
              :state="usernameValidation"></b-form-input>
            <b-form-invalid-feedback :state="usernameValidation">{{ usernameValidationMessage }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <b-form-input
              type="email"
              v-model="email"
              id="email"
              class="form-control-lg"
              placeholder="メールアドレス"
              :state="emailValidation"></b-form-input>
            <b-form-invalid-feedback :state="emailValidation">{{ emailValidationMessage }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <b-form-input
              type="text"
              v-model="confirmedEmail"
              id="confirmedEmail"
              class="form-control-lg"
              placeholder="メールアドレス（再入力）"
              @focusout="checkConfirmedEmail"></b-form-input>
          </b-form-group>
          <b-form-group
            description="大文字・小文字・数字・特殊記号を含む10文字以上">
            <b-form-input
              type="password"
              v-model="password"
              id="password"
              class="form-control-lg"
              placeholder="パスワード"
              :state='passwordValidation'></b-form-input>
            <b-form-invalid-feedback :state="passwordValidation">{{ passwordValidationMessage }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <b-form-input
              type="password"
              v-model="confirmedPassword"
              id="confirmedPassword"
              class="form-control-lg"
              placeholder="パスワード（再入力）"
              @focusout="checkConfirmedPassword"></b-form-input>
          </b-form-group>
          <b-form-group
            class="pt-2"
          >
            <div>
              PERAGARUに登録することで、
              <b-link v-on:click='gotoPrivacyPolicy'>プライバシーポリシー</b-link>
              ・
              <b-link v-on:click='gotoTerms'>利用規約</b-link>
              に同意したものとみなします。
            </div>
          </b-form-group>
          <div>
            <b-button
              v-on:click="next"
              class="btn btn-primary btn-block signup-next-btn">次へ</b-button>
          </div>
        </b-form>
        </template>

        <template
          v-else>
          <div class="text-center">
            <h5 class="mb-4">新規会員登録（確認）</h5>
          </div>
          <b-row>
            <b-col cols="3" class="mt-5">お名前</b-col>
            <b-col cols="9" class="mt-5">{{ username }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="3" class="mt-5">メールアドレス</b-col>
            <b-col cols="9" class="mt-5">{{ email }}</b-col>
          </b-row>
          <b-row>
            <b-col class="mt-5">
              <b-button
              v-on:click="back"
              :disabled="isSending"
              class="btn btn-secondary back-btn w-100">戻る</b-button>
            </b-col>
            <b-col class="mt-5">
              <b-button
                v-on:click="doSignup"
                :disabled="isSending"
                class="btn btn-primary execute-btn w-100">アカウント作成</b-button>
            </b-col>
          </b-row>
        </template>
      </div>
    </div>
  </b-container>
</template>

<script>
import Signup from '../lib/model/user/signup';

import * as Http from '../lib/http/http';
import * as Analytics from '../lib/infrastructure/google/analytics';

import Init from './Init.vue';

export default {
  name: 'Signup',
  mixins: [
    Init,
  ],
  components: {
  },
  data: () => ({
    phase: 'INPUT',
    isSending: false,
    isSuccess: false,
    isExistingError: false,
    inquiryFormLink: process.env.VUE_APP_GOOGLE_FORM_LINK,
    username: '',
    usernameValidationMessage: '',
    email: '',
    emailValidationMessage: '',
    confirmedEmail: '',
    password: '',
    passwordValidationMessage: '',
    confirmedPassword: '',
    loginErrorMessage: '',
    status: '',
    signInStatus: {},
  }),
  created() {},
  mounted() {},
  methods: {
    showMsgModal(title, msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: `${title}`,
        size: 'mid',
        buttonSize: 'mid',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    next() {
      if (this.usernameValidation
      && this.emailValidation
      && this.passwordValidation) {
        this.phase = 'CONFIRM';
        this.scrollTop();
      }
    },
    back() {
      this.phase = 'INPUT';
      this.isExistingError = false;
    },
    async doSignup() {
      this.isSending = true;
      let flag = false;
      flag = await this.checkUsernameExisting();
      flag = flag || await this.checkEmailExisting();
      console.log('flag', flag);
      if (flag) {
        this.isExistingError = true;
        this.isSending = false;
        this.showMsgModal('確認してください', '名前かメールアドレスが既に登録されています');
        return;
      }
      const res = await Signup.doSignup({
        username: this.username,
        email: this.email,
        password: this.password,
      });
      if (res) {
        this.isSending = false;
        Analytics.sendSignUpEvent();
        this.showMsgModal('登録完了', 'あなたのメールアドレスに認証コードを送りました。\nメールのリンクをクリックしてメールアドレスの認証を完了させてください。\nメールが届かない場合はお問い合わせよりご連絡ください。');
      } else {
        this.isSending = false;
        this.showMsgModal('登録エラー', 'エラーが発生しました。\nお手数ですがお問い合わせよりご連絡ください。');
      }
    },
    checkUsernameExisting() {
      return Http.post(
        `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/user/check/username`,
        '',
        { username: this.username },
      )
        .catch((err) => {
          console.error(err);
          return [];
        });
    },
    async checkEmailExisting() {
      return Http.post(
        `https://${process.env.VUE_APP_BACKEND_USER_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/user/check/email`,
        '',
        { email: this.email },
      ).catch((err) => {
        console.error(err);
        return [];
      });
    },
    checkConfirmedEmail() {
      if (this.confirmedEmail !== this.email) this.showMsgModal('確認してください', 'メールアドレスが再入力されたメールアドレスと一致していません');
    },
    checkConfirmedPassword() {
      if (this.confirmedPassword !== this.password) this.showMsgModal('確認してください', 'パスワードが再入力されたパスワードと一致していません');
    },
    gotoPrivacyPolicy() {
      window.open(process.env.VUE_APP_PRIVACY_POLICY);
    },
    gotoTerms() {
      window.open('/terms');
    },
  },
  computed: {
    usernameValidation() {
      // 必須 && 全角ではない && 空白文字が含まれていない
      // eslint-disable-next-line no-control-regex
      return this.username !== '' && !this.username.match(/[^\x01-\x7E\xA1-\xDF]+/) && !this.username.match(/[\u{20}\u{3000}]+/u);
    },
    emailValidation() {
      // 必須 && email形式 && 空白文字が含まれない
      // eslint-disable-next-line no-control-regex
      return this.email !== '' && !!this.email.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i) && !this.email.match(/[^\x01-\x7E\xA1-\xDF]+/);
    },
    passwordValidation() {
      // ref) https://qiita.com/mpyw/items/886218e7b418dfed254b#%E5%8D%8A%E8%A7%92%E8%8B%B1%E6%95%B0%E5%AD%97%E8%A8%98%E5%8F%B7%E3%82%92%E3%81%9D%E3%82%8C%E3%81%9E%E3%82%8C1%E7%A8%AE%E9%A1%9E%E4%BB%A5%E4%B8%8A%E5%90%AB%E3%82%808%E6%96%87%E5%AD%97%E4%BB%A5%E4%B8%8A100%E6%96%87%E5%AD%97%E4%BB%A5%E4%B8%8B%E3%81%AE%E6%AD%A3%E8%A6%8F%E8%A1%A8%E7%8F%BE
      // 1. (「最初に記号 or A-Z」が0桁以上で[a-z]を含む)
      // 2. (「最初に記号 o ra-z」が0桁以上で[A-Z]を含む)
      // 3. (「最初に記号」が0桁以上で数字を含む)
      // 4. (「a-zA-Z数字」が0桁以上記号を含む)
      // 以上の条件で10桁以上
      return this.password !== '' && !!this.password.match(/^(?=[!-@[-`{-~A-Z]{0,}[a-z])(?=[!-@[-`{-~a-z]{0,}[A-Z])(?=[!-/:-~]{0,}\d)(?=[a-zA-Z\d]{0,}[!-/:-@[-`{-~])[!-~]{10,}$/);
    },
  },
  watch: {
    username() {
      if (this.username === '') {
        this.usernameValidationMessage = '※必須です';
      }
      // eslint-disable-next-line no-control-regex
      if (this.username.match(/[^\x01-\x7E\xA1-\xDF]+/)) {
        this.usernameValidationMessage = '全角文字が含まれています';
      }
      if (this.username.match(/[\u{20}\u{3000}]+/u)) {
        this.usernameValidationMessage = '空白文字が含まれています';
      }
    },
    email() {
      if (this.email === '') {
        this.emailValidationMessage = '※必須です';
      }
      if (this.email !== ''
        && (
          !this.email.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)
          // eslint-disable-next-line no-control-regex
          || !!this.email.match(/[^\x01-\x7E\xA1-\xDF]+/)
        )
      ) {
        this.emailValidationMessage = 'メールアドレスの形式に誤りがあります';
      }
    },
    password() {
      if (this.password === '') {
        this.passwordValidationMessage = '※必須です';
      }
      if (this.password !== '' && !this.password.match(/^(?=[!-@[-`{-~A-Z]{0,}[a-z])(?=[!-@[-`{-~a-z]{0,}[A-Z])(?=[!-/:-~]{0,}\d)(?=[a-zA-Z\d]{0,}[!-/:-@[-`{-~])[!-~]{10,}$/)) {
        this.passwordValidationMessage = 'パスワードの形式に誤りがあります';
      }
    },
  },
};
</script>

<style scoped>

</style>
