import BaseEntity from '../base-entity';

import { keyCheck } from '../../helper/object-helper';

export default class User extends BaseEntity {
  constructor(obj) {
    super(obj);
    this.name = keyCheck(obj, 'name') ? obj.name : '';
    this.email = keyCheck(obj, 'email') ? obj.email : '';
  }
}
