<template>
  <footer>
    <b-container class="text-center">
      <p><a href="#" class="twitter" @click="gotoTwitter"></a></p>
      <p><a href="#" class="brand" @click="goto('/')"></a></p>
      <b-list-group
        horizontal='md'
        class="list">
        <a href="#"
          class="list-group-item list-group-item-action"
          @click="gotoCompanyProfile">
          運営会社
        </a>
        <a href="#"
          class="list-group-item list-group-item-action"
          @click="gotoPrivacyPolicy">
          プライバシーポリシー
        </a>
        <a href="#"
          class="list-group-item list-group-item-action"
          @click="goto('/terms')">
          利用規約
        </a>
        <a href="#"
          class="list-group-item list-group-item-action"
          @click="goto('/contact')">
          お問い合わせ
        </a>
      </b-list-group>
      <p class="copyright">&copy; 2020 hands</p>
    </b-container>
  </footer>
</template>

<script>
import router from '../router';

export default {
  name: 'Footer',
  components: {},
  data: () => ({}),
  created() {},
  methods: {
    goto(link) {
      if (link.includes('http')) {
        // eslint-disable-next-line no-restricted-globals
        window.open(link);
        return;
      }
      // eslint-disable-next-line no-restricted-globals
      if (link === '/') location.href = link;
      router.push({ path: link });
    },
    gotoTwitter() {
      window.open('https://twitter.com/PERAGARU1');
    },
    gotoCompanyProfile() {
      window.open(process.env.VUE_APP_HANDS_COMPANY_PAGE);
    },
    gotoPrivacyPolicy() {
      window.open(process.env.VUE_APP_PRIVACY_POLICY);
    },
  },
};
</script>
