<template>
  <v-parallax
    src="landing.jpg"
    height="800"
  >
    <v-container
      grid-list-md
      text-xs-center
      fill-height
    >
      <v-layout
        row
        text-xs-center
        wrap
        align-center
        justify-center
      >
        <v-flex
          xs9
          sm10
          md11
        >
          <v-text-field
            solo
            light
            full-width
            text-xs-center
            type="number"
            clearable
            label="検索したい銘柄コードを入力してください"
            :messages="[searchErrorMessage]"
            v-model="securityCode"
          />
        </v-flex>

        <v-flex
          xs1
          sm1>
          <v-btn
            bottom
            fab
            color="indigo"
            @click="search"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-flex>

      </v-layout>
    </v-container>
  </v-parallax>
</template>

<script>
/* eslint-disable import/no-cycle */
import router from '../../router';

export default {
  name: 'Landing',
  components: {
  },
  data: () => ({
    securityCode: '',
    searchErrorMessage: '',
    errorText: {
      shortLength: '2文字以上の数字を入力してください',
    },
  }),
  methods: {
    search() {
      // console.log(this.securityCode);
      if (this.securityCode.toString().length < 2) {
        this.searchErrorMessage = this.errorText.shortLength;
        return;
      }
      router.push({ path: `/company/${this.securityCode.toString()}` });
    },
  },
};
</script>
