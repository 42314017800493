<template>
  <b-container>
    <span class="loading-icon">
    </span>
    <ProjectInfo :projectInfo='projectInfo' />
    <MetricsEcharts
      :projectName='projectName'
      :metrics='metrics'
      :typeWithDeficientList='typeWithDeficientList'
      :typeWithScrapeInfo='typeWithScrapeInfo'
      :fiscalEndMonth='projectInfo.fiscalEndMonth'
    />
    <MetricsEcharts
      v-show="packageMetricsExisting"
      :projectName='projectName'
      :metrics='packageMetrics'
      :typeWithDeficientList='packageTypeWithDeficientList'
      :typeWithScrapeInfo='packageTypeWithScrapeInfo'
      :fiscalEndMonth='projectInfo.fiscalEndMonth'
    />
    <MetricsAdditionalContract
      :v-show="noContractMetricTypesExisting"
      :projectName="projectName"
      :metricTypes="noContractMetricTypes"
    />
    <h2 v-show="relatedMetricsExisting"
      class="mt-5">
      Related Data
    </h2>
    <MetricsEcharts
      v-show="relatedMetricsExisting"
      :projectName='projectName'
      :metrics='relatedMetrics'
      :typeWithDeficientList='relatedTypeWithDeficientList'
      :typeWithScrapeInfo='relatedTypeWithScrapeInfo'
      :fiscalEndMonth='projectInfo.fiscalEndMonth'
    />
  </b-container>
</template>

<script>
import * as moment from 'moment-timezone';
import { mapActions } from 'vuex';
import * as Http from '../lib/http/http';
import Project from '../lib/model/project/project';
import Type from '../lib/model/project/type';
import Session from '../lib/model/user/session';
import Init from './Init.vue';
import ProjectInfo from '../components/ProjectInfo.vue';
import MetricsEcharts from '../components/MetricsEcharts.vue';
import MetricsAdditionalContract from '../components/MetricsAdditionalContract.vue';

export default {
  name: 'ProjectDetail',
  mixins: [
    Init,
  ],
  components: {
    ProjectInfo,
    MetricsEcharts,
    MetricsAdditionalContract,
  },
  data: () => ({
    sessionToken: {},
    projectName: '',
    projectInfo: new Project(),
    metrics: [],
    typeWithDeficientList: {},
    typeWithScrapeInfo: {},
    packageMetricsExisting: false,
    packageMetrics: [],
    packageTypeWithDeficientList: {},
    packageTypeWithScrapeInfo: {},
    relatedMetricsExisting: false,
    relatedMetrics: [],
    relatedTypeWithDeficientList: {},
    relatedTypeWithScrapeInfo: {},
    availableMetricTypes: [],
    availablePackageMetricTypes: [],
    noContractMetricTypes: [],
    noContractMetricTypesExisting: false,
  }),
  methods: {
    ...mapActions({
      getMetricsFromLocal: 'Metrics/getMetricsFromLocal',
      upsertMetricsWithProject: 'Metrics/upsertMetricsWithProject',
      removeMetrics: 'Metrics/removeMetrics',
      resetMetrics: 'Metrics/resetMetrics',
    }),
    async init() {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return;
      }
      this.sessionToken = token;
      this.availableMetricTypes = [];
      await Promise.all([
        this.fetchProjectInfo(),
        this.fetchMetrics(),
        this.fetchPackageMetrics(),
        this.fetchRelatedMetrics(),
      ]);
      await Promise.all([
        this.fetchAvailableMetricTypes(),
        this.fetchAvailablePackageMetricTypes(),
      ]);
      if (!this.metrics.length && !this.packageMetrics.length && !this.relatedMetrics.length) this.showMsgModal('確認可能なデータが存在しません', '追加でご契約が必要なデータ、もしくは表示に必要な期間のデータが不足しているため表示ができません。');
      this.calcNoContractMetricTypes();
    },
    async fetchProjectInfo() {
      this.projectInfo = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_VERSION}/info/${this.projectName}`,
        this.sessionToken,
      )
        .then((p) => new Project(p))
        .catch((e) => {
          console.error(e);
          return [];
        });
    },
    async fetchMetricsFromLocal() {
      this.metrics = await this.getMetricsFromLocal(this.projectName);
    },
    async fetchMetrics() {
      const { metrics, typeWithDeficientList, typeWithScrapeInfo } = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_VERSION}/translated/metrics/${this.projectName}`,
        this.sessionToken,
      ).catch((e) => {
        console.error(e);
        return [];
      });
      if (!metrics) {
        this.removeMetrics({ projectName: this.projectName });
        return;
      }
      this.metrics = metrics;
      this.typeWithDeficientList = typeWithDeficientList;
      this.typeWithScrapeInfo = typeWithScrapeInfo;
      this.upsertMetricsWithProject({ projectName: this.projectName, metrics });
    },
    async fetchPackageMetrics() {
      // APIバージョンを並行させる為にハードコーディング
      // 本番リリース後にAPIバージョンを${process.env.VUE_APP_BACKEND_VERSION}に書き換える事
      const { metrics, typeWithDeficientList, typeWithScrapeInfo } = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/package-project-metrics/translated/${this.projectName}`,
        this.sessionToken,
      ).catch((e) => {
        console.error(e);
        return [];
      });
      if (!metrics
        || !Array.isArray(metrics)
        || !metrics.length) {
        return;
      }
      this.packageMetricsExisting = true;
      this.packageMetrics = metrics;
      this.packageTypeWithDeficientList = typeWithDeficientList;
      this.packageTypeWithScrapeInfo = typeWithScrapeInfo;
    },
    async fetchRelatedMetrics() {
      // APIバージョンを並行させる為にハードコーディング
      // 本番リリース後にAPIバージョンを${process.env.VUE_APP_BACKEND_VERSION}に書き換える事
      const { metrics, typeWithDeficientList, typeWithScrapeInfo } = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/2/translated/related-project-metrics/${this.projectName}`,
        this.sessionToken,
      ).catch((e) => {
        console.error(e);
        return [];
      });
      if (!metrics
        || !Array.isArray(metrics)
        || !metrics.length) {
        return;
      }
      this.relatedMetricsExisting = true;
      this.relatedMetrics = metrics;
      this.relatedTypeWithDeficientList = typeWithDeficientList;
      this.relatedTypeWithScrapeInfo = typeWithScrapeInfo;
    },
    async fetchAvailableMetricTypes() {
      const metricTypes = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/metric-types/available/${this.projectName}`,
        this.sessionToken,
      ).catch((e) => {
        console.error(e);
        return [];
      });
      if (!metricTypes
        || !Array.isArray(metricTypes)
        || !metricTypes.length) {
        return;
      }
      this.availableMetricTypes = metricTypes.map((type) => new Type(type));
    },
    async fetchAvailablePackageMetricTypes() {
      const packageMetricTypes = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_USER_VERSION}/package-metric-types/available/${this.projectName}`,
        this.sessionToken,
      ).catch((e) => {
        console.error(e);
        return [];
      });
      console.log('packageMetricTypes', packageMetricTypes);
      if (!packageMetricTypes
        || !Array.isArray(packageMetricTypes)
        || !packageMetricTypes.length) {
        return;
      }
      this.availablePackageMetricTypes = packageMetricTypes.map((type) => new Type(type));
    },
    calcNoContractMetricTypes() {
      if (!this.availableMetricTypes.length) return;
      const typeDescriptionList = [...new Set([...Object.keys(this.typeWithScrapeInfo), ...Object.keys(this.packageTypeWithScrapeInfo)])];
      this.noContractMetricTypes = [...new Map( // 重複排除
        [
          ...this.availableMetricTypes
            .filter((type) => !!type.typeDescription
              && !typeDescriptionList.includes(type.typeDescription)
              // データ取得開始から30日以上経過してるもののみ出力する
              && type.startDate
              && moment().diff(type.startDate, 'days') > 30)
            .map((type) => [type.typeKey, type]),
          ...this.availablePackageMetricTypes
            .filter((type) => !!type.typeDescription
              && !typeDescriptionList.includes(type.typeDescription)
              // データ取得開始から30日以上経過してるもののみ出力する
              && type.startDate
              && moment().diff(type.startDate, 'days') > 30)
            .map((type) => [type.typeKey, type]),
        ],
      ).values()];
      if (this.noContractMetricTypes.length) this.noContractMetricTypesExisting = true;
    },
    showMsgModal(title, msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: `${title}`,
        size: 'mid',
        buttonSize: 'mid',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
  },
  async created() {
    this.projectName = decodeURIComponent(this.$route.params.projectName);
    // await this.init();
    await this.fetchMetricsFromLocal();
  },
  async mounted() {
    await this.init();
  },
};
</script>
