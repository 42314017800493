import Vue from 'vue';
import Vuex from 'vuex';

import ProjectList from './modules/project/project-list';
import Metrics from './modules/project/metrics';
import Login from './modules/user/login';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    ProjectList,
    Metrics,
    Login,
  },
});
