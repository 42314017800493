<template>
  <b-container class="front for-personal">
    <b-row align-v="start">
      <b-col cols="12" order-lg="0">
        <h1 class="font-weight-bold">TVメタデータ</h1>
      </b-col>
      <b-col cols="12" order-lg="0" class="my-3">
        TVメタデータとは、TV-CMの企業名や商品・ブランド名、TV番組で放送されたニュースや話題の内容（露出銘柄など）と、
        その放送時間を独自にテキストデータ化したものを指します。当該データを日本の上場企業に紐づける形でご提供しております。
      </b-col>
      <b-col cols="12" order-lg="0" class="mt-2">
        <img src="/cm-wantedly2.png" alt="no img" class="w-100 py-0">
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12">
        <h4 class="mb-3 font-weight-bold">提供銘柄一覧</h4>
      </b-col>
      <b-col cols="4" md="3" class="p-1"
        v-for='company in companyList' :key='company.securityCode'>
        <div class="pera-card h-100 p-3 m-0">
          {{ company.companyDisplayName }}({{ company.securityCode }})
        </div>
      </b-col>
    </b-row>
    <b-row align-v="start">
      <b-col cols="12" order-lg="0" class="mt-5">
        <h4 class="mb-3 font-weight-bold">データ活用例</h4>
        <h5 class="mb-3 font-weight-bold">3991 ウォンテッドリー</h5>
        <div class="pera-card">
          3991ウォンテッドリーの業績を予測しようとした場合、以下の分析フローが考えられます。
          <ul class="mt-2">
            <li>売上高を分解したKPIである有料契約企業数と契約単価に関するオルタナティブデータを収集し、それらをかけて公表予定の四半期の売上高を予測する。</li>
            <li>費用項目の大部分を占める人件費について、社員数に関するオルタナティブデータを取得し、1人あたり人件費との積で人件費総額を算出する。</li>
            <li>同じく費用の大部分を占める広告宣伝費について、各広告媒体の出稿量と単位当たり金額についてのオルタナティブデータを取得する。</li>
            <li>上記で求めた売上高と費用を元に財務モデルを作成して営業利益を算出し、バリュエーションを求める。</li>
          </ul>
          売上高について、有料企業契約数はPERAGARUにて提供しており、契約単価については利用者へのヒアリングや同社への資料請求等で知ることができます。
          また、社員数についても同様に、推定値をPERAGARUにて提供しております。そして広告宣伝費について、TV-CMの放送時間についてはTVメタデータを利用して把握することが可能です。ウォンテッドリーはタクシー広告やweb広告の出稿も行うことが多いため、これらの出稿量データも取得すれば更に高い精度で業績の予測が可能になります。
        </div>
      </b-col>
      <b-col cols="12" order-lg="0" class="mt-5">
        <h4 class="mb-3 font-weight-bold">基本情報</h4>
        <div class="pera-card">
          <div class="font-weight-bold">データ元</div>
          <div>エム・データ社とのデータ連携</div>
          <div class="mt-3 font-weight-bold">データ利用料金</div>
          <div>ASK</div>
          <div class="mt-3 font-weight-bold">データの粒度・更新頻度</div>
          <div>日次</div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Init from '../Init.vue';
// import router from '../router';

export default {
  components: {},
  mixins: [
    Init,
  ],
  name: 'Cinecon',
  data: () => ({
    companyList: [
      {
        companyDisplayName: 'ラクス',
        securityCode: 3923,
      },
      {
        companyDisplayName: 'マネーフォワード',
        securityCode: 3994,
      },
      {
        companyDisplayName: 'freee',
        securityCode: 4478,
      },
      {
        companyDisplayName: 'Sansan',
        securityCode: 4443,
      },
      {
        companyDisplayName: 'インフォマート',
        securityCode: 2492,
      },
      {
        companyDisplayName: 'Appier Group',
        securityCode: 4180,
      },
      {
        companyDisplayName: 'プラスアルファ・コンサルティング',
        securityCode: 4071,
      },
      {
        companyDisplayName: 'サイボウズ',
        securityCode: 4776,
      },
      {
        companyDisplayName: 'プレイド',
        securityCode: 4165,
      },
      {
        companyDisplayName: 'HENNGE',
        securityCode: 4475,
      },
      {
        companyDisplayName: 'ユーザベース',
        securityCode: 3966,
      },
      {
        companyDisplayName: 'スパイダープラス',
        securityCode: 4192,
      },
      {
        companyDisplayName: 'スマレジ',
        securityCode: 4431,
      },
      {
        companyDisplayName: 'カオナビ',
        securityCode: 4435,
      },
      {
        companyDisplayName: '手間いらず',
        securityCode: 2477,
      },
      {
        companyDisplayName: 'ヤプリ',
        securityCode: 4168,
      },
      {
        companyDisplayName: 'チャットワーク',
        securityCode: 4448,
      },
      {
        companyDisplayName: 'ユーザーローカル',
        securityCode: 3984,
      },
      {
        companyDisplayName: 'カナミックネットワーク',
        securityCode: 3939,
      },
      {
        companyDisplayName: 'ブイキューブ',
        securityCode: 3681,
      },
      {
        companyDisplayName: 'サイバーセキュリティクラウド',
        securityCode: 4493,
      },
      {
        companyDisplayName: 'ウォンテッドリー',
        securityCode: 3991,
      },
      {
        companyDisplayName: 'フォトシンス',
        securityCode: 4379,
      },
      {
        companyDisplayName: 'チームスピリット',
        securityCode: 4397,
      },
      {
        companyDisplayName: 'rakumo',
        securityCode: 4060,
      },
      {
        companyDisplayName: 'ロジザード',
        securityCode: 4391,
      },
      {
        companyDisplayName: 'ピー・シー・エー',
        securityCode: 9629,
      },
      {
        companyDisplayName: '弁護士ドットコム',
        securityCode: 6027,
      },
      {
        companyDisplayName: 'ショーケース',
        securityCode: 3909,
      },
      {
        companyDisplayName: 'CINC',
        securityCode: 4378,
      },
    ],
  }),
  mounted() {},
  methods: {},
};
</script>
