<template>
  <div class="pera-panel info-panel">
    <h5>大量保有報告書一覧</h5>
    <ul class="list-group list-group-flush">
    <template v-for="report in shareHoldingReportList">
      <li class="list-group-item" :key="report.id">
        {{ formatDisplay(report) }} / 株主: {{ report.shareholder }}
      </li>
    </template>
    </ul>
  </div>
</template>

<script>
import * as moment from 'moment';
import Session from '../../lib/model/user/session';
import ShareHoldingReport from '../../lib/model/stock/share-holding-report';

import * as Http from '../../lib/http/http';

export default {
  name: 'ShareHoldingReportList',
  props: [
    'securityCode',
    'inheritedSessionToken',
  ],
  components: {},
  data: () => ({
    shareHoldingReportList: [],
    sessionToken: {},
  }),
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return;
      }
      this.sessionToken = token;
      if (!this.securityCode || this.securityCode === '0') return;
      const list = await Http.get(
        `https://${process.env.VUE_APP_BACKEND_STOCK_DOMAIN}/${process.env.VUE_APP_BACKEND_STOCK_VERSION}/share-holding-report/list/${this.securityCode}`,
        this.sessionToken,
      ).catch((e) => {
        console.error(e);
        return [];
      });

      this.shareHoldingReportList = list
        .map((d) => new ShareHoldingReport(d))
        .sort((a, b) => (a.date < b.date ? 1 : -1));
    },
    formatDisplay(report) {
      return `${this.formatDate(report.date)} - ${report.title.replace('&lt;', '[').replace('&gt;', ']')}`;
    },
    formatDate(date) {
      return moment(date).format('MM/DD HH:mm');
    },
  },
  computed: {},
  watch: {
    async securityCode() {
      await this.init();
    },
  },
};
</script>

<style scoped>

</style>
