<template>
  <div v-if='filteredIndexGroupList.length !== 0'>
    <h2>契約指数一覧</h2>
    <div class="pera-panel">
      <b-list-group v-for='(indexGroup, i) in filteredIndexGroupList' :key='indexGroup.indexGroupName'>
        <b-row :class="[i !== filteredIndexGroupList.length - 1 ? 'mb-3' : '']">
          <b-col cols="12" class="brand-name">
            <details>
              <summary class="pera-brand">
                {{ indexGroup.indexGroupDisplayName }}
              </summary>
              <b-list-group v-for='index in indexGroup.indexInfoList' :key='index.id'>
                <Index
                  v-if="!index.deleteFlag"
                  :index='index'
                />
              </b-list-group>
            </details>
          </b-col>
        </b-row>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import * as Http from '../lib/http/http';
import Session from '../lib/model/user/session';
import Index from './Index.vue';
// import IndexModel from '../lib/model/index/index';
import IndexGroup from '../lib/model/index/index-group';

export default {
  name: 'IndexList',
  mixins: [],
  components: {
    Index,
  },
  data: () => ({
    indexGroupList: [],
    indexList: [],
    filteredIndexGroupList: [],
    searchJoinedTextList: [],
    searchText: '',
  }),
  methods: {
    search() {
      this.filteredIndexGroupList = this.indexGroupList;
      if (!this.searchText || this.searchText === '') return;
      this.filteredIndexGroupList = this.filteredIndexGroupList
        .filter((_, idx) => !!this.searchJoinedTextList[idx].includes(this.searchText));
    },
    createSearchJoinedTextList() {
      this.searchJoinedTextList = this.indexGroupList.map((ig) => `${ig.indexGroupName}__${ig.indexGroupDisplayName}`);
    },
    async init() {
      const token = await Session.getSession();
      if (!token) {
        console.error('Session Error.');
        return;
      }
      const fetchedIndexGroupList = await Http.get(`https://${process.env.VUE_APP_BACKEND_DOMAIN}/${process.env.VUE_APP_BACKEND_VERSION}/index-metrics/info-in-group/list`, token);
      if (!fetchedIndexGroupList || !Array.isArray(fetchedIndexGroupList)) return;

      const indexGroupList = fetchedIndexGroupList.map((ig) => new IndexGroup(ig));
      this.indexGroupList = indexGroupList;
      this.filteredIndexGroupList = indexGroupList;
      this.createSearchJoinedTextList();
    },
  },
  // async created() {},
  async mounted() {
    await this.init();
  },
};
</script>

<style lang="scss" scoped>
  /*@import '../assets/scss/custom';*/
  /*@import '~bootstrap/scss/bootstrap.scss';*/
  .alpha { background-color: purple; }

  .pera-panel {
    //background-color: #303030;
    padding: 1rem 2rem;
  }

  details summary {
    list-style: none;
    font-size: 1.3rem;
  }

  details summary {
    list-style: none;
    cursor: pointer;
  }
  /**
   * Chrome用のデフォルト三角削除
  **/
  details summary::-webkit-details-marker {
    display: none;
  }
  /**
   * Font Awesomeのプラスアイコン使用
  **/
  details summary::before {
    font-family: FontAwesome;
    content: '\f067';
    margin-right: 10px;
  }
  /**
   * アコーディオンがオープン時はマイナスアイコンに変更
  **/
  details[open] summary::before {
    content: '\f068';
  }
</style>
