<template>
  <div class="pera-brand">
    <a class="link-item" v-on:click="toDetail(packageProject.companyName)">
      <b-row>
        <b-col cols="12" md="2" class="brand-logo">
          <img class="img-fluid" :src=displayImage>
        </b-col>
        <b-col cols="12" md="4" class="brand-name">
          <label>銘柄名</label>
          <h4>{{ displayName }}</h4>
        </b-col>
        <b-col cols="4" md="2" class="brand-code">
          <label>銘柄コード</label>
          <p>{{ displayCode }}</p>
        </b-col>
        <b-col cols="8" md="4" class="brand-url">
          <label>URL</label>
          <button @click="gotoCompanyProfile"
              type="button"
              class="btn btn-link">
            {{ displayUrl }}
          </button>
        </b-col>
      </b-row>
    </a>
  </div>
</template>

<script>
import router from '../router';

export default {
  name: 'Project',
  props: [
    'packageProject',
  ],
  created() {},
  mounted() {},
  methods: {
    toDetail(cn) {
      router.push({ path: `/package/detail/${cn}` });
    },
    gotoCompanyProfile(event) {
      if (!!this.packageProject.companyUrl && this.packageProject.companyUrl !== 'Null') {
        window.open(this.packageProject.companyUrl);
        event.stopPropagation();
      }
    },
  },
  computed: {
    displayImage() {
      return this.packageProject.companyImageUrl && this.packageProject.companyImageUrl !== 'Null' ? this.packageProject.companyImageUrl : '/in-preparation-img.png';
    },
    displayName() {
      console.log('this.packageProject.companyDisplayName', this.packageProject.companyDisplayName);
      return this.packageProject.companyDisplayName
        ? this.packageProject.companyDisplayName : this.packageProject.companyName;
    },
    displayCode() {
      return this.packageProject.securityCode;
    },
    displayUrl() {
      return !!this.packageProject.companyUrl && this.packageProject.companyUrl !== 'Null' ? this.packageProject.companyUrl : '-';
    },
  },
};
</script>

<style scoped>
</style>
