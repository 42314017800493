import BaseEntity from '../base-entity';

import { keyCheck } from '../../helper/object-helper';

export const Unit = {
  fiveMinutes: 'fiveMinutes',
  hourly: 'hourly',
  daily: 'daily',
  weekly: 'weekly',
  threeMonths: 'threeMonths',
  halfAYear: 'halfAYear',
  yearly: 'yearly',
};

export default class StockPrice extends BaseEntity {
  constructor(obj) {
    super(obj);
    this.securityCode = keyCheck(obj, 'securityCode') ? obj.securityCode : '';
    this.openingPrice = keyCheck(obj, 'openingPrice') ? obj.openingPrice : 0;
    this.closingPrice = keyCheck(obj, 'closingPrice') ? obj.closingPrice : 0;
    this.highPrice = keyCheck(obj, 'highPrice') ? obj.highPrice : 0;
    this.lowPrice = keyCheck(obj, 'lowPrice') ? obj.lowPrice : 0;
    this.volume = keyCheck(obj, 'volume') ? obj.volume : 0;
    this.adjustedClosingPrice = keyCheck(obj, 'adjustedClosingPrice') ? obj.adjustedClosingPrice : 0;
    this.unit = keyCheck(obj, 'unit') ? Unit[obj.unit] : '';
  }
}
